<!-- Toolbar -->
<div class="toolbar" fxLayout="row">

  <div class="toolbarleft" fxLayout="row nowrap">
    <div class="toolbarlogo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path fill="var(--appcolor-base);"
          d="M10.09,15.59,11.5,17l5-5-5-5L10.09,8.41,12.67,11H3v2h9.67ZM19,3H5A2,2,0,0,0,3,5V9H5V5H19V19H5V15H3v4a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Z" />
      </svg>
    </div>
    <div class="toolbarlefttext link" routerLink="/">Accueil</div>
  </div>

</div>
