import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '..';
import { tokenSelector } from './auth.selectors';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptor implements HttpInterceptor {

  private token: string;

  constructor(
    private store: Store<State>,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.pipe(first(), select(tokenSelector))
    .subscribe((token: string) => {
      this.token = token;
    });
    // this.token = localStorage.getItem('token');
    // console.log('->[ AuthInterceptor/this.token ]: ', this.token);
    if (this.token) {
      const authReq = req.clone({
        headers: req.headers.set('SecurityToken', this.token)
        // body: req.body.set('jwtlocal', token)
        // params: req.params.append('jwtlocal', token)
      });
      return next.handle(authReq);
    } else {
      // console.log('->[ AuthInterceptor/request ]: ', req);
      return next.handle(req);
    }
  }
}
