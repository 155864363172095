import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { OverviewService } from 'src/app/shared/services/overview.service';
import { ExplorerService } from 'src/app/shared/services/explorer.service';
import { DomeventsService } from 'src/app/shared/services/domevents.service';
import { SlideProp, VideoSectionProp, LinkProp } from '../../shared/models/layout.model';
import overviewDatas from '../../shared/content/overview-datas';

/**
* @title class OverviewComponent
* @description overview component - suposed to present the behaviour of the company with the minimum of slide
*
*/
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: [
    '../../shared/css/app.component.css',
    '../front.component.css',
    './overview.component.css',
  ],
})

export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  public overviewVideoPath = environment.appRootPath + environment.appMediasPath + "/overview";
  public videoProp: VideoSectionProp;
  public slide: SlideProp;
  public linksDatas = Object.values(overviewDatas.links);
  private link: LinkProp;
  private interval: number;

  @ViewChild('logoOuter', { read: ElementRef }) logoouter: ElementRef;
  @ViewChild('logoInner', { read: ElementRef }) logoinner: ElementRef;
  @ViewChildren('videos', { read: ElementRef }) videos: QueryList<ElementRef>;
  @ViewChildren('links', { read: ElementRef }) links: QueryList<ElementRef>;
  @ViewChildren('screens', { read: ElementRef }) screens: QueryList<ElementRef>;
  @ViewChildren('backImages ', { read: ElementRef }) backImages: QueryList<ElementRef>;

  constructor(
    public overviewService: OverviewService,
    public layoutService: LayoutService,
    public explorerService: ExplorerService,
    public domeventsService: DomeventsService,
    private renderer: Renderer2,
    private router: Router,
    // private cdRef: ChangeDetectorRef
  ) {
    overviewService.renderer = renderer;
    layoutService.renderer = renderer;
    this.slide = { y: 0, direction: 0, top: 0, curScreen: -1, prevScreen: -1, stop: 0, delay: 0, curObj: null, prevObj: null };
    this.link = { index: 0, color: 'var(--appcolor-white)', text: 'linktext' }
    console.log('=>[ OverviewComponent/constructor ]', Object.values(overviewDatas.links));
  }

  ngOnInit(): void {
    this.layoutService.setFrontLayout();
    this.videoProp = this.layoutService.setVideoProp();
  }

  ngAfterViewInit(): void {
    this.setOverviewWrap();
    this.overviewService.setOverviewServiceViewDatas({ screens: this.screens, links: this.links, slide: this.slide, link: this.link });
    this.layoutService.setViewChildrenBorder({ vcTarget: this.backImages });
    this.overviewService.scrollInit();
    // this.overviewService.setActiveLink({links: this.links, index: 0});
  }

  setOverviewWrap(): void {
    this.layoutService.setFrontLayout();
    this.videoProp = this.layoutService.setVideoProp();
    this.layoutService.setViewChildrenBorder({ vcTarget: this.backImages, flag: 2 });
  }

  ngOnDestroy(): void {
    // this.sub.unsubscribe();
    clearInterval(this.interval);
    console.log('=>[ OverviewComponent/ngOnDestroy ]');
  }

};




