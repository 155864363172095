import { Injectable, Inject, ElementRef, Renderer2, QueryList, ViewChildren } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { SlideProp, LinkProp } from '../models/layout.model';
// import linksDatas from '../content/overview-datas';

/**
* @title class OverviewService dedecated to overview.component
* @description frontEnd initial component
*/
@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  public renderer: Renderer2;
  private slide: SlideProp;
  private link: LinkProp;
  private links: QueryList<ElementRef>;
  private screens: QueryList<ElementRef>;
  private slideDelay = 700;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public layoutService: LayoutService,
  ) { }

  public setOverviewServiceViewDatas({ screens, links, slide, link }: { screens?: QueryList<ElementRef>; links?: QueryList<ElementRef>; slide?: SlideProp; link?: LinkProp }) {
    if (screens) { this.screens = screens; }
    if (slide) { this.slide = slide; }
    if (links) { this.links = links; }
    if (link) { this.link = link; }
  }

  /**
 * @title linksSwitchClass
 * @description switch the class of links depending on the screen number and odd or even
 * @parameter links: QueryList, cs: the current screen onscroll
 * @return void
 */
  public linksSwitchClass({ links, cs }: { links: QueryList<ElementRef>; cs: number; }): void {
    setTimeout(() => this.slideDelay);
    links.forEach((key: any, index: number) => {
      // key.nativeElement.textContent = linksDatas.links[index]; // can affect directly the text of the tag
    if (cs % 2 === 0) {
        this.renderer.removeClass(key.nativeElement, 'overview-direct-links-letter-odd');
        this.renderer.addClass(key.nativeElement, 'overview-direct-links-letter-even');
      } else {
        this.renderer.removeClass(key.nativeElement, 'overview-direct-links-letter-even');
        this.renderer.addClass(key.nativeElement, 'overview-direct-links-letter-odd');
      }
    });
  }

  /**
 * @title linksClick
 * @description switch the class of links depending on the screen number and odd or even
 * @parameter links: QueryList, cs: the current screen onscroll
 * @return void
 */
  public linksClick(e: any) {
    let index = parseInt(e.originalTarget.getAttribute('data-link'));
    console.log('=>[ OverviewService/linksClick ]', parseInt(e.originalTarget.getAttribute('data-link')));
    this.slideScreenDirect(index);
  }

  /**
 * @title scrollInit
 * @description init of scroll / slide the first page and wait 5sec
 * @parameter no parameter
 * @return void
 */
  public scrollInit(): void {
    this.slidePrepare({ screens: this.screens, excludeScreen: null, top: window.innerHeight });
    // this.linksClick(0);
    setTimeout(() => {
      this.scrollCatch(0);
    }, 500);
  }

  /**
 * @title scrollCatch
 * @description catch the scrollmouse event / does not occur if slide.stop not equal to -1
 * @parameter e: any can be the scroll event
 * @return void
 */
  public scrollCatch(e: any): void {
    if (this.slide.stop === -1) { return };
    this.slide.y === 0 ? this.slide.y = 1 : this.slide.y = e.deltaY;
    this.slide.y > 0 ? this.slide.direction = 1 : this.slide.direction = -1;
    this.slide.top = this.slide.direction * window.innerHeight;
    this.slide.direction === 1 ? (this.slide.curScreen + 1 > this.screens.length - 1 ? this.slide.curScreen = 0 : this.slide.curScreen += 1)
      : this.slide.curScreen - 1 < 0 ? this.slide.curScreen = this.screens.length - 1 : this.slide.curScreen -= 1;
    this.slide.stop = -1;
    this.scrollSlide({ screens: this.screens, slide: this.slide });
  }

  /**
 * @title scrollSlide
 * @description throw slide prepare and execute the slide between the prev and the cur screen
 * @parameter no parameter
 * @return void
 */
  public scrollSlide({ screens, slide }: { screens: QueryList<ElementRef>; slide: SlideProp; }): void {
    this.slidePrepare({ screens: screens, excludeScreen: slide.prevScreen, top: slide.top });
    setTimeout(() => {
      if (slide.prevScreen != -1) { slide.prevObj = this.slideScreen({ screens: screens, targetScreen: slide.prevScreen, top: (-1 * slide.top) }); }
      slide.curObj = this.slideScreen({ screens: screens, targetScreen: slide.curScreen, top: 0 });
      this.slide = slide;
    }, 50);
    setTimeout(() => {
      this.slide = { ...slide, prevScreen: slide.curScreen, stop: 0 }
    }, this.slideDelay);
    this.linksSwitchClass({ links: this.links, cs: this.slide.curScreen }); // this.linksClick(targetScreen);
  }

  /**
* @title slidePrepare
* @description put all the screens in the right position ready to slide correctly
* @parameter get the QueryList from the component and other param
* @return void
*/
  public slidePrepare({ screens, excludeScreen, top }: { screens: QueryList<ElementRef>; excludeScreen: number; top: number; }): void {
    screens.forEach((key: any, index: any) => {
      if (index != excludeScreen) {
        this.renderer.removeClass(key.nativeElement, 'overview-screen-slide');
        this.renderer.setStyle(key.nativeElement, 'top', `${top}px`);
      }
    });
  }

  /**
* @title slideScreen
* @description basic slide used in other functions
* @parameter get the QueryList from the component and other param like target screen and the top position
* @return the targetScreenObj
*/
  public slideScreen({ screens, targetScreen, top }: { screens: QueryList<ElementRef>; targetScreen: number; top: number; }): any {
    let targetScreenObj = screens.toArray()[targetScreen].nativeElement;
    this.renderer.addClass(targetScreenObj, 'overview-screen-slide');
    this.renderer.setStyle(targetScreenObj, 'top', `${top}px`);
    return targetScreenObj;
  }

  /**
* @title slideScreenDirect
* @description execute the slide directly to the page submited
* @parameter p the page number
* @return void
*/
  public slideScreenDirect(p: number): void {
    if (this.slide.prevScreen === p || this.slide.stop === -1) { return };
    this.slide = { ...this.slide, curScreen: p, stop: -1, y: this.slide.curScreen - this.slide.prevScreen }
    this.slide.y = this.slide.curScreen - this.slide.prevScreen;
    this.slide.y > 0 ? this.slide.direction = 1 : this.slide.direction = -1;
    this.slide.top = this.slide.direction * window.innerHeight;
    let screensArray = [];
    if (this.slide.y > 0) {
      screensArray = this.screens.toArray().splice(this.slide.prevScreen, this.slide.y + 1)
    } else {
      screensArray = this.screens.toArray().reverse().splice(this.slide.curScreen, Math.abs(this.slide.y) + 1)
    }
    this.slide.delay = 0;
    this.slideIteration(this.slide.prevScreen, screensArray);
  }

  /**
* @title slideIteration
* @description execute the slide from curScreen to target until the screen clicked with link is reached
* @parameter curScreen the screen from where the iteration starts
* @return void
*/
  private slideIteration(curScreen: number, screensArray: any): void {
    this.slide.stop = -1;
    setTimeout(() => {
      this.slideIterationFlow({ ts: curScreen + this.slide.direction, ps: curScreen });
      if (curScreen + this.slide.direction === this.slide.curScreen) {
        this.slide.curScreen = curScreen + this.slide.direction;
        this.slide.prevScreen = curScreen;
        return
      };
      this.slide.delay = this.slideDelay;
      if (this.slide.direction === 1) {
        if (curScreen++ != screensArray.length - 1) { this.slideIteration(curScreen, screensArray); }
      } else {
        if (curScreen-- != 0) { this.slideIteration(curScreen, screensArray); }
      }
    }, this.slide.delay);
    this.linksSwitchClass({ links: this.links, cs: this.slide.curScreen }); // this.linksClick(targetScreen);
  }

  /**
* @title slideIterationFlow
* @description thrown by slideIteration to simulate the scroll down / make a pause
* @parameter targetScreen and previous (current)
* @return void
*/
  public slideIterationFlow({ ts, ps }: { ts: number; ps: number; }): void {
    this.slidePrepare({ screens: this.screens, excludeScreen: ps, top: this.slide.top });
    setTimeout(() => {
      this.slideScreen({ screens: this.screens, targetScreen: ps, top: (-1 * this.slide.top) });
      this.slideScreen({ screens: this.screens, targetScreen: ts, top: 0 });
    }, 50);
    setTimeout(() => {
      this.slide.prevScreen = this.slide.curScreen;
      this.slide.stop = 0;
    }, this.slideDelay);
  }

}
