export default {
  '/home': {
    title: 'web agence et éditions, iasct"',
    metas: {
      description: "web agence et éditions, iasct",
      'og:title': "web agence et éditions, iasct",
    }
  },
  '/signin': {
    title: "web agence et éditions, iasct",
        metas: {
      description: 'web agence et éditions, iasct',
      'og:title': "web agence et éditions, iasct",
    }
  }
};
