import { Action } from '@ngrx/store';
import { User } from '../../models/user.model';

export const TRY_SIGNUP = '[ auth ] try signup';
export const SIGNUP_ERROR = '[ auth ] signup error';
export const TRY_SIGNIN = '[ auth ] try signin';
export const SIGNIN_SUCCESS = '[ auth ] signin success';
export const SIGNIN_ERROR = '[ auth ] signin error';
export const TRY_FETCH_CURRENT_USER = '[ auth ] try fetch current user';
export const SET_CURRENT_USER = '[ auth ] set current user';
export const UPDATE_CURRENT_USER = '[ auth ] update current user';
export const TRY_REFRESH_TOKEN = '[ auth ] try refresh token';

export const LOGOUT = '[ auth ] loggout';

export class TrySignup implements Action {
  readonly type = TRY_SIGNUP;
  constructor(public payload: User) { }
}

export class SignupError implements Action {
  readonly type = SIGNUP_ERROR;
  constructor(public payload: any) { }
}

export class TrySignin implements Action {
  readonly type = TRY_SIGNIN;
  constructor(public payload: {
    email: string,
    password: string
  }) { }
}

export class SigninSuccess implements Action {
  readonly type = SIGNIN_SUCCESS;
  constructor(public payload: any) { }
}

export class SigninError implements Action {
  readonly type = SIGNIN_ERROR;
  constructor(public payload: any) { }
}

export class TryFetchCurrentUser implements Action {
  readonly type = TRY_FETCH_CURRENT_USER;
}

export class SetCurrentUser implements Action {
  readonly type = SET_CURRENT_USER;
  constructor(public payload: User) { }
}

export class UpdateCurrentUser implements Action {
  readonly type = UPDATE_CURRENT_USER;
  constructor(public payload: User) { }
}

export class TryRefreshToken implements Action {
  readonly type = TRY_REFRESH_TOKEN;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}


export type AuthActions =
  TrySignup |
  SignupError |
  TrySignin |
  SigninSuccess |
  SigninError |
  TryFetchCurrentUser |
  SetCurrentUser |
  UpdateCurrentUser |
  TryRefreshToken |
  Logout;
