import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import {
  GET_ROUTER_URL, GetRouterUrl
} from './router.actions';

import { State } from '..';
import { myRouterStateRoot, myRouterState } from './router.selectors';

@Injectable()
export class RouterEffects {
  private sub: Subscription;
  private data: string;

  @Effect({ dispatch: false })
  GetRouterUrl$ = this.actions$.pipe(
    ofType(GET_ROUTER_URL),
    switchMap(() => {
      return this.store.pipe(
        select(myRouterState),
        tap((x) => console.log(x)),
        map((baseUrl: any) => {
          this.data = baseUrl.state.url;
          console.log(this.data);
          this.data = baseUrl.state.param;
          console.log(this.data);
        }),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
  ) { }

}
