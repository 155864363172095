import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Store } from '@ngrx/store';
import { State } from '../../shared/store';
import { LayoutService } from '../../shared/services/layout.service';
import { ExplorerService } from '../../shared/services/explorer.service';
import { Router } from '@angular/router';
import { HttpResponse } from '../../shared/models/httpResponse.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-video',
  templateUrl: './media.component.html',
  styleUrls: [
    '../../shared/css/app.component.css',
    './media.component.css',
  ],
})
export class MediaComponent implements OnInit, AfterViewInit {
  public videoWidth: any;
  public sectionIndex = 1;
  public sectionsCount = [];
  public videosCount = [[]];
  public filesList = [];
  public filesListTemplate = [];
  public mediasPath = environment.appMediasPath;
  private activeCard: ElementRef;
  private activeSection: ElementRef;
  private activeCardLeft = 0;
  private activeSectionLeft = 0;

  @ViewChild('videoCardContainer', { read: ElementRef }) videoCardContainer: ElementRef;
  @ViewChildren('videoCard', { read: ElementRef }) videoCards: QueryList<ElementRef>;
  @ViewChildren('section', { read: ElementRef }) sections: QueryList<ElementRef>;

  constructor(
    public layoutService: LayoutService,
    private explorerService: ExplorerService,
    private renderer: Renderer2,
    private store: Store<State>,
    private router: Router,
    private el: ElementRef,
    private viewportScroller: ViewportScroller,
  ) {
    console.log('->[ MediaComponent/constructor ]');
  }

  /**
   * @title videoComponent
   * @description frontEnd video component / display list of media on server
   */

  ngOnInit(): void {
    this.layoutService.setFrontLayout();
    this.videoWidth = (this.layoutService.windowProp.width) / 1.5;
    this.formatFileList();
    console.log('->[ MediaComponent/ngOnInit ]: ');
  }

  ngAfterViewInit(): void {

  }

  public formatFileList(): void {
    this.explorerService.getFileList(environment.appMediasPath).subscribe((resp: HttpResponse) => {
      console.log('->[ MediaComponent/filesList ]: ', resp);
      this.filesList = resp.datas.target_path_files;
      console.log('->[ MediaComponent/getFileList ]: ', this.filesList);
      const sectionLenght = (Math.ceil((this.filesList.length) / this.layoutService.videoProp.count));
      for (let i = 1; i <= sectionLenght; i++) {
        this.sectionsCount.push(i);
        this.videosCount[i] = [];
        if (i !== sectionLenght) {
          for (let ii = 0; ii < this.layoutService.videoProp.count; ii++) {
            i === 1 ? this.videosCount[i].push(ii) : this.videosCount[i].push((this.videosCount[i - 1].length * (i - 1)) + ii);
          }
        } else {
          for (let ii = 0; ii < (this.filesList.length - ((sectionLenght - 1) * this.layoutService.videoProp.count)); ii++) {
            i === 1 ? this.videosCount[i].push(ii) : this.videosCount[i].push((this.videosCount[i - 1].length * (i - 1)) + ii);
          }
        }
      }
      console.log('->[ MediaComponent/sectionCount ]: ', this.sectionsCount);
    });
  }

  public slideSections(s: number): void {
    for (const section of this.sections.toArray()) {
      this.activeSection = section.nativeElement;
      this.activeSectionLeft = parseInt((getComputedStyle(Object(this.activeSection)).left), 10);
      s === 1 ? (this.sectionIndex !== this.sections.length ? this.activeSectionLeft =
        this.activeSectionLeft - this.layoutService.sectionProp.width : this.activeSectionLeft = 0) :
        (this.sectionIndex !== 1 ? this.activeSectionLeft = this.activeSectionLeft + this.layoutService.sectionProp.width :
          this.activeSectionLeft = - ((Math.round(this.sections.length) - 1) * this.layoutService.sectionProp.width));
      this.renderer.setStyle((this.activeSection), 'left', (this.activeSectionLeft + 'px'));
    }
    s === 1 ? (this.sectionIndex !== this.sections.length ? this.sectionIndex++ : this.sectionIndex = 1) :
      (this.sectionIndex !== 1 ? this.sectionIndex-- : this.sectionIndex = this.sections.length);
    // console.log('=>[ MediaComponent/slideSections ] ', (this.sectionIndex));
    // console.log('=>[ MediaComponent/slideSections ] ', (this.activeSectionLeft));
    // console.log('=>[ MediaComponent/slideSections ] ', (this.layoutService.sectionProp.width));
    // console.log('=>[ --------------- ---------------------------------] ');
  }

  public carouselSlideCards(s: number): void {
    for (const card of this.videoCards.toArray()) {
      this.activeCard = card.nativeElement;
      this.activeCardLeft = parseInt((getComputedStyle(Object(this.activeCard)).left), 10);
      if (s === 1) {
        // if (this.activeCardLeft !== 0) {
        this.activeCardLeft = this.activeCardLeft - 92 - (this.layoutService.cardProp.width);
        this.renderer.setStyle((this.activeCard), 'left', (this.activeCardLeft + 'px'));
        // }
        // console.log('=>[ MediaComponent/carouselSlide/left ] ', ((this.activeCardLeft - (this.layoutService.cardProp.width - 80)) + 'px'));
      } else {
        if (this.activeCardLeft !== 0) {
          this.activeCardLeft = this.activeCardLeft + 92 + (this.layoutService.cardProp.width);
          this.renderer.setStyle((this.activeCard), 'left', (this.activeCardLeft + 'px'));
        }
      }
    }
    console.log('=>[ MediaComponent/carouselSlide ] ', (this.sections.length));
    console.log('=>[ MediaComponent/carouselSlide ] ', (this.videoCards.length));
    console.log('=>[ MediaComponent/carouselSlide ] ', (this.layoutService.cardProp.width));
    console.log('=>[ MediaComponent/carouselSlide ] ', (this.videoCards.last.nativeElement));
    console.log('=>[ MediaComponent/carouselSlide ] ', (this.activeCardLeft));
    console.log('=>[ --------------- ---------------------------------] ');
  }

  public setVideoWrapper(): void {
    this.videoWidth = (this.layoutService.windowProp.width) / 1.5;

  }
}
