import { Action } from '@ngrx/store';

export const SET_CURRENT_APIPATH = '[ temp ] set current api path';
export const IS_ROUTER_ENABLED = '[ temp ] is router enabled';
export const SET_ROUTER_NEXTURL = '[ temp ] set router nextUrl';
export const SET_WINDOW_SIZE = '[ temp ] set window size';

export class SetCurrentApiPath implements Action {
  readonly type = SET_CURRENT_APIPATH;
  constructor(public payload: string) { }
}

export class SetRouterNextUrl implements Action {
  readonly type = SET_ROUTER_NEXTURL;
  constructor(public payload: string) {}
}

export class SetWindowSize implements Action {
  readonly type = SET_WINDOW_SIZE;
  constructor(public payload: {width: number, height: number, ratio: number, windowCenter: {top: number, left: number}}) {}
}

export class IsRouterEnabled implements Action {
  readonly type = IS_ROUTER_ENABLED;
  constructor(public payload: boolean) { }
}

export type tempActions = IsRouterEnabled |
                            SetRouterNextUrl |
                            SetWindowSize |
                            SetCurrentApiPath;
