import { Inject, Injectable } from '@angular/core';
import { MouseProp, ScrollProp } from '../models/layout.model';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DomeventsService {
  public mouseProp: MouseProp;
  public scrollProp: ScrollProp = { x: 0, y: 0, notch: 0, direction: 0 };

  constructor(
    @Inject(DOCUMENT) private document: any,
  ) { }

  public detectMousePosition(event: any): void {
    this.mouseProp = { x: event.x, y: event.y }
    // console.log('->[ LayoutService/detectMousePosition ]', event);
  }

  public detectScroll(event: any): ScrollProp {
    this.scrollProp.x += event.deltaX;
    this.scrollProp.y += event.deltaY;
    switch (true) {
      case event.deltaY >= 0: this.scrollProp.notch += 1; this.scrollProp.direction = 1; break;
      case event.deltaY < 0: this.scrollProp.notch -= 1; this.scrollProp.direction = -1; break;
    }
    this.scrollProp.y = 0;
    return this.scrollProp;
  }
}
