import {
  AuthActions, SIGNUP_ERROR, SIGNIN_ERROR,
  SIGNIN_SUCCESS, LOGOUT, SET_CURRENT_USER, TRY_SIGNUP, TRY_SIGNIN,
  UPDATE_CURRENT_USER
} from './auth.actions';
import { AuthState } from '../../models/stateIndex.model';

const initialState = {
  user: null,
  token: null,
  error: null,
  isLoggedin: null
};

export function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case SIGNIN_ERROR:
    case SIGNUP_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case TRY_SIGNIN:
    case TRY_SIGNUP: {
      return {
        ...state,
        error: null
      };
    }
    case SIGNIN_SUCCESS: {
      return {
        ...state,
        token: action.payload,
        isLoggedin: true,
        error: null
      };
    }
    case LOGOUT: {
      return {
        ...state,
        user: null,
        token: null,
        error: null,
        isLoggedin: false
      };
    }
    case UPDATE_CURRENT_USER:
    case SET_CURRENT_USER: {
      return {
        ...state,
        user: action.payload,
        isLoggedin: true
      };
    }
  }
  return state;
}
