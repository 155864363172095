import { ViewCompileResult } from '@angular/compiler/src/view_compiler/view_compiler';
import {
  OnInit, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked,
  Component, ElementRef, OnDestroy, ViewChild, ViewContainerRef, ContentChild, Renderer2
} from '@angular/core';
import { BehaviorSubject, Observable, TimeInterval, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    '../../css/app.component.css'
  ]
})
export class HeaderComponent implements OnInit, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy {
  public headerVideoWidth: number;
  public headerVideoHeight: number;
  public videoBuffer = new BehaviorSubject<string>('');
  currentBuffer = this.videoBuffer.asObservable();

  @ViewChild('video01', { read: ElementRef }) video01: ElementRef;

  constructor(
    private layoutService: LayoutService,
    private renderer: Renderer2,

  ) { }

  ngOnInit(): void {
    this.setVideoWrapper();
  }

  ngAfterContentInit(): void {
    console.log('->[ HeaderComponent/ngAfterContentInit ]: ');

  }

  ngAfterContentChecked(): void {
    // console.log('->[ HeaderComponent/ngAfterContentChecked ]: ');

  }

  ngAfterViewInit(): void {
    console.log('->[ HeaderComponent/ngAfterViewInit ]: ');
    // this.videoBuffer.next(this.video01.nativeElement.buffered.length);
    // this.videoBuffer.subscribe(valeur => {
    //   console.log('La valeur de mon autre objet est ', valeur);
    // Valeur vaut au moment de la souscription la valeur "valeurAvantSouscription"
    // });
    // this.videoBuffer = this.video01.nativeElement.play().subscribe(val => {
    // this.renderer.setStyle((this.video01.nativeElement), 'paused', ('false'));
    // this.video01.nativeElement.paused = false;
    // console.log('->[ HeaderComponent/ngAfterViewInit ]: ', this.video01.nativeElement);
    // });

  }

  ngAfterViewChecked(): void {
    // console.log('->[ HeaderComponent/ngAfterViewChecked ]: ');
    // console.log('->[ HeaderComponent/ngAfterViewChecked ]: ', this.video01.nativeElement);
    // this.video01.nativeElement.play();
  }

  ngOnDestroy(): void {
    console.log('-----------------------------[ ngOnDestroy]');
  }

  public checkforVideo(e): void {
    const interval = setInterval(() => {
      console.log('->[ HeaderComponent/checkforVideo ]: ', e);
      console.log('->[ HeaderComponent/checkforVideo ]: ', this.video01.nativeElement.buffered.length);
      console.log('->[ HeaderComponent/checkforVideo ]: ', this.video01.nativeElement.readyState);

      if (this.video01.nativeElement.readyState >= 3) {
        // This block of code is triggered when the video is loaded
        // your code goes here
        // stop checking every half second
        clearInterval(interval);
        this.video01.nativeElement.play();

      }
    }, 500);
  }

  public setVideoWrapper(): void {
    this.headerVideoWidth = this.layoutService.windowProp.width;
    this.headerVideoHeight = 80;
    // this.headerVideoHeight = (this.layoutService.windowProp.height / 3);
  }

}
