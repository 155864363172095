import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TempState } from '../../models/stateIndex.model';

export const tempSelector = createFeatureSelector('temp');

export const tempStateRoot = createSelector(tempSelector,
  (tempState: TempState) => {
    if (tempState) {
      return tempState;
    } else {
      return null;
    }
  }
);

export const apiPath = createSelector(tempSelector,
  (tempState: TempState) => {
    if (tempState) {
      return tempState.apiPath;
    } else {
      return null;
    }
  }
);

export const nextUrl = createSelector(tempSelector,
  (tempState: TempState) => {
    if (tempState) {
      return tempState.nexturl;
    } else {
      return null;
    }
  }
);

export const windowSize = createSelector(tempSelector,
  (tempState: TempState) => {
    if (tempState) {
      return tempState.windowSize;
    } else {
      return null;
    }
  }
);

export const isRouterEnabled = createSelector(tempSelector,
  (tempState: TempState) => {
    if (tempState) {
      return tempState.isRouterEnabled;
    } else {
      return null;
    }
  }
);
