import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { SnackBarComponent } from '../snack-bar/snackbar.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: [
    './toolbar.component.css',
    // '../../css/app.component.css'
  ]
})
export class ToolbarComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    public snackBar: SnackBarComponent,
    public alertService: AlertService, // do not delete => launch of alertService
  ) { }

  ngOnInit(): void {
  }

}
