import { Action } from '@ngrx/store';
import { SettingsTitle } from '../../models/settingsTitle.model';

export const SETTINGS_TITLE_CREATE = '[settingsTitle] create';
export const SETTINGS_TITLE_DELETE = '[settingsTitle] delete';
export const SETTINGS_TITLE_TOGGLE = '[settingsTitle] toggle';
export const FETCH_SETTINGS_TITLE = '[settingsTitle] fetch';
export const FETCH_SETTINGS_TITLE_LIST = '[settingsTitle] fetchlist';
export const FETCH_SETTINGS_TITLE_SUCCESS = '[settingsTitle] fetch success';
export const FETCH_SETTINGS_TITLE_ERROR = '[settingsTitle] fetch error';

export class CreateSettingsTitle implements Action {
  readonly type = SETTINGS_TITLE_CREATE;
  constructor(public payload: SettingsTitle) {}
}

export class DeleteSettingsTitle implements Action {
  readonly type = SETTINGS_TITLE_DELETE;
  constructor(public payload: number) {}
}

export class ToggleSettingsTitle implements Action {
  readonly type = SETTINGS_TITLE_TOGGLE;
  constructor(public payload: number) {}
}

export class FetchSettingsTitle implements Action {
  readonly type = FETCH_SETTINGS_TITLE;
}

export class FetchSettingsTitleList implements Action {
  readonly type = FETCH_SETTINGS_TITLE_LIST;
}

export class FetchSettingsTitleSuccess implements Action {
  readonly type = FETCH_SETTINGS_TITLE_SUCCESS;
  constructor(public payload: SettingsTitle[]) {}
}

export class FetchSettingsTitleError implements Action {
  readonly type = FETCH_SETTINGS_TITLE_ERROR;
  constructor(public payload: any) {}
}

export type SettingsTitlesActionType = CreateSettingsTitle |
                              DeleteSettingsTitle |
                              ToggleSettingsTitle |
                              FetchSettingsTitle |
                              FetchSettingsTitleList |
                              FetchSettingsTitleSuccess |
                              FetchSettingsTitleError;
