import { User } from '../../models/user.model';
import * as usersAction from './users.actions';
import { UserState } from '../../models/stateIndex.model';
const initialState = {
  datas: [{
    us_id: null,
    us_identity_id: null,
    us_nickname: null,
    us_email: null,
    us_password: null,
    us_initials: null,
    us_name: null,
    us_firstname: null,
    us_role: null
  }],
  loading: false,
  loaded: false,
  error: null
};

export function usersReducer(state: UserState = initialState, action: usersAction.UsersActionType): UserState {
  switch (action.type) {
    case usersAction.FETCH_USER:
      return {
        ...state,
        loading: true
      };
    case usersAction.FETCH_USER_SUCCESS:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        loaded: true,
        error: null
      };
    case usersAction.FETCH_USER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload
      };
    case usersAction.USER_CREATE:
      return {
        ...state,
        datas: [...state.datas, action.payload]
      };
    case usersAction.USER_DELETE:
      return {
        ...state,
        datas: state.datas.filter((t, i) => i !== action.payload)
      };
    default:
      return state;
  }
}
