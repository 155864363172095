import { Action } from '@ngrx/store';

export const SET_TERMINAL_STATE_MESSAGE = '[ terminal ] set message';
export const UPDATE_TERMINAL_STATE_MESSAGE = '[ terminal ] update message';
export const SET_TERMINAL_STATE_TIME = '[ terminal ] set time';
export const SET_TERMINAL_STATE_CONSOLE = '[ terminal ] set console';
export const SET_TERMINAL_STATE_ERROR = '[ terminal ] error';

export class SetTerminalStateMessage implements Action {
  readonly type = SET_TERMINAL_STATE_MESSAGE;
  constructor(public payload: any) { }
}
export class UpdateTerminalStateMessage implements Action {
  readonly type = UPDATE_TERMINAL_STATE_MESSAGE;
}
export class SetTerminalStateTime implements Action {
  readonly type = SET_TERMINAL_STATE_TIME;
  constructor(public payload: any) { }
}
export class SetTerminalStateConsole implements Action {
  readonly type = SET_TERMINAL_STATE_CONSOLE;
  constructor(public payload: any) { }
}
export class SetTerminalStateError implements Action {
  readonly type = SET_TERMINAL_STATE_ERROR;
  constructor(public payload: string) { }
}

export type TerminalActions =
  SetTerminalStateMessage |
  UpdateTerminalStateMessage |
  SetTerminalStateTime |
  SetTerminalStateConsole |
  SetTerminalStateError;
