import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FileList } from '../../shared/models/explorer.model';
import { HttpResponse } from '../../shared/models/httpResponse.model';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor(
    private http: HttpClient,
    private table: string,
  ) { }

  public getTableFields(): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(`${environment.appApiPath}/generic/generic_get_fields.php`,
      { table: this.table });
  }

}

