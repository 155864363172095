import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TempService } from '../services/temp.service';

export interface IsDirty {
  isFormDirty(): boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})

export class IsNotDirtyGuard implements CanDeactivate<IsDirty> {
  constructor(
    private tempService: TempService,
  ) { }

  canDeactivate(
    component: IsDirty,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    this.tempService.setNextUrl(nextState.url);
    return component.isFormDirty();

  }

}
