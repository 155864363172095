import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '../models/httpResponse.model';
import { environment } from 'src/environments/environment';

export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}

export function checkPasswordValidator(controlName: string, matchingControlName: string): any {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    // console.log('[ matchingControl.errors ]', matchingControl.errors);
    if (matchingControl.errors && !matchingControl.errors.checkPasswordValidator) {
      console.log('[ matchingControl.errors ]', matchingControl.errors);
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
    console.log('[ mustMatch ]', matchingControl.status);
  };
}

@Injectable({
  providedIn: 'root'
})
export class UniqueEmailValidator {
  constructor(
    private http: HttpClient,
  ) { }

  emailValidator(currentUserEmail): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return timer(1000).pipe(
        switchMap(() => {
          return this.isEmailTaken(control.value)
            .pipe(
              map(res => {
                // if username is already taken
                // console.log('[ UniqueEmailValidator, emailValidator ]', res);

                if (!res.datas.length && (control.value !== currentUserEmail)) {
                  // return error
                  return { userEmailExists: true };
                }
              })
            );
        })
      );
    };
  }

  public isEmailTaken(email: string): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(`${environment.appApiPath}/objects/user/user_email_check.php`, '{ "us_email" : "' + email + '"}');
  }
}
