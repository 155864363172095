import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { State } from '../../../shared/store';
import { Store, select } from '@ngrx/store';

import { errorAuthSelector } from '../../../shared/store/auth-store/auth.selectors';
import { AuthService } from '../../../shared/services/auth.service';
import { TrySignup } from '../../../shared/store/auth-store/auth.actions';
import { FormsService } from 'src/app/shared/services/forms.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: [
    '../../../shared/css/app.component.css',
    '../../../shared/css/app.forms.css',
    'signup.component.css'
  ]
})
export class SignupComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public error$: Observable<string>;

  constructor(
    private fb: FormBuilder,
    public formsService: FormsService,
    private store: Store<State>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [''],
      name: [''],
      password: ['']
    });
    this.error$ = this.store.pipe(
      select(errorAuthSelector)
    );
  }

  ngOnDestroy(): void {
    // this.formsService.ResetFormsState();
  }

  public submit(): void {
    console.log('[ SignupComponent/test ]: ', this.form.value);
    this.store.dispatch(new TrySignup(this.form.value));
  }

}
