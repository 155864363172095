import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { State } from '../../../shared/store';
import { TrySignin } from '../../../shared/store/auth-store/auth.actions';
import { errorAuthSelector } from '../../../shared/store/auth-store/auth.selectors';

import { FormsService } from 'src/app/shared/services/forms.service';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: [
    '../../../shared/css/app.component.css',
    '../../../shared/css/app.forms.css',
    'signin.component.css'
  ]
})
export class SigninComponent implements OnInit {
  public form: FormGroup;
  public user: User;
  public error$: Observable<string>;

  constructor(
    public formsService: FormsService,
    private fb: FormBuilder,
    private store: Store<State>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [''],
      password: ['']
    });
    this.error$ = null;
  }

  submit(): void {
    // console.log(this.store.dispatch(new TrySignin(this.form.value)));
    this.store.dispatch(new TrySignin(this.form.value));
    this.error$ = this.store.pipe(select(errorAuthSelector));
  }
}
