import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, interval } from 'rxjs';
import { map, filter, take, tap, switchMap, mapTo } from 'rxjs/operators';


interface User {
  name: string;
  age: number;
  email: string;
}

function createStream(name: string, iterations: number, intervalle: number): Observable<any> {
  return interval(intervalle).pipe(
    take(iterations),
    tap(val => {
    }),
    map(val => {
      return `[ Stream ${name} ] : ${val}`;
    }));
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  constructor() { }
  public datas: any;
  public x = 1;

  ngOnInit(): void {
    const behaviorSubject = new BehaviorSubject<User>({
      name: 'Jean',
      age: 28,
      email: 'jean@gmail.com',
    });

    // const s1 = behaviorSubject
    //   .pipe(
    //     filter((x: User) => x.email.length > 5), // la fonction passée à filter doit retourner un booléen
    //     map((x: User) => x.email)
    //   )
    //   .subscribe((x: string) => console.log('[s1] : ', x));

    const streamA = createStream('A', 1, 1000).subscribe(res => {
      return this.datas = res;
    });
    const streamB = createStream('B', 10, 100).subscribe(res => {
      return this.datas = res;
    });
  }
}
