import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
// local imports
import { State } from '../../store';
import { User } from '../../models/user.model';
import { isLoggedinSelector, currentUserSelector } from '../../store/auth-store/auth.selectors';
import { Logout } from '../../store/auth-store/auth.actions';
import { FormsService } from '../../services/forms.service';

// import { LayoutService } from '../../services/layout.service';
// import { TempService } from '../../services/temp.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: [
    './topbar.component.css'
  ],
  providers: [
  ]
})

export class TopbarComponent implements OnInit {
  public device: string;
  public isLoggedin$: Observable<boolean>;
  public currentUser$: Observable<User>;
  public isrouterEnabled$: Observable<boolean>;

  constructor(
    // private tempService: TempService,
    private store: Store<State>,
    private formsService: FormsService
  ) { }

  ngOnInit() {
    this.isLoggedin$ = this.store.pipe(select(isLoggedinSelector));
    this.currentUser$ = this.store.pipe(select(currentUserSelector));
    this.isrouterEnabled$ = this.formsService.isformDirty$;
    // this.layoutService.device.subscribe((device: string) => {
    // this.device = device;
    // console.log('->[ TopbarComponent/this.layoutService.device ]: ', this.device);
    // });
  }

  public logout(): void {
    this.store.dispatch(new Logout());
  }
}
