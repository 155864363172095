<div class="iconmenu-wrap">
  <div class="iconmenu-scrolltext"> fluctuat nec mergitur </div>
  <div class="iconmenu-scrolltext"> | </div>
  <div class="iconmenu-link link" data-tag="overview" routerLink="/overview">
    <div>overview</div>
  </div>
  <div class="iconmenu-scrolltext"> | </div>
  <div *ngIf="!(isLoggedin$ | async)" class="iconmenu-icon link" data-tag="sign-in" routerLink="/home/signin">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path class="icon-color-none" d="M0 0h24v24H0V0z" />
      <path class="icon-color-fillstroke"
        d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z" />
    </svg>
  </div>
  <div *ngIf="!(isLoggedin$ | async)" class="iconmenu-icon link" data-tag="sign-up" routerLink="/home/signup">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path class="icon-color-none" d="M0 0h24v24H0V0z" />
      <path class="icon-color-none" d="M0 0h24v24H0V0z" />
      <path class="icon-color-fillstroke"
        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z" />
    </svg>
  </div>
  <!-- <div *ngIf="(isLoggedin$ | async) && !(isrouterEnabled$ | async)" class="iconmenu-icon link" data-tag="profile" routerLink="/home/profile" -->
  <div *ngIf="(isLoggedin$ | async)" class="iconmenu-icon link" data-tag="profile" routerLink="/home/profile"
    matTooltip="profil" matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path class="icon-color-none" d="M0 0h24v24H0V0z" />
      <path class="icon-color-fillstroke"
        d="M17.59 3.59c-.38-.38-.89-.59-1.42-.59H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7.83c0-.53-.21-1.04-.59-1.41l-2.82-2.83zM12 19c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm1-10H7c-1.1 0-2-.9-2-2s.9-2 2-2h6c1.1 0 2 .9 2 2s-.9 2-2 2z" />
    </svg>
  </div>
  <div *ngIf="(isLoggedin$ | async)" class="iconmenu-icon link" data-tag="log-out" routerLink="/home/log-out">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path class="icon-color-none" d="M0,0h24v24H0V0z" />
      <path class="icon-color-fillfill"
        d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
    </svg>
  </div>

  <div *ngIf="(isrouterEnabled$ | async)" class="iconmenu-icon link" data-tag="log-out" routerLink=""
    matTooltip="sauvegarder" matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path class="icon-color-none" d="M0 0h24v24H0V0z" />
      <path class="icon-color-fillfill"
        d="M17.59 3.59c-.38-.38-.89-.59-1.42-.59H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7.83c0-.53-.21-1.04-.59-1.41l-2.82-2.83zM12 19c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm1-10H7c-1.1 0-2-.9-2-2s.9-2 2-2h6c1.1 0 2 .9 2 2s-.9 2-2 2z" />
    </svg>
  </div>

</div>
