import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  SetCurrentApiPath
} from './temp.actions';

import { State } from '..';

@Injectable()
export class TempEffects {
  private subscription: Subscription;

  // @Effect()
  // SetCurrentApiPath$ = this.actions$.pipe(
  //   ofType(FETCH_APIPATH),
  //   map((path) => {
  //     return new SetCurrentApiPath(path);
  //   }));

constructor(
  private actions$: Actions,
  private store: Store < State >,
) { }

}
