import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { State } from '../store';
import { isLoggedinSelector } from '../store/auth-store/auth.selectors';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private store: Store<State>,
    private router: Router,
  ) {
    // console.log('50------------------------------[ AuthGuard/constructor/initialization ]');

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      take(1),
      select(isLoggedinSelector),
      tap(val => {
        if (val === false) {
          this.router.navigateByUrl('/');
        }
        return val;
      })
    );

  }

}
