<div class="front-wrap">

  <div class="front-cell-A01">
    <div class="front-cell-label">A01</div>
    <app-idlogo class="front-cell-wrap"></app-idlogo>
  </div>

  <div class="front-cell-A02">
    <div class="front-cell-label">A02</div>
    <app-iconmenu class="front-cell-wrap"></app-iconmenu>
  </div>

  <!-- <div class="front-cell B01">
    <div class="front-cell-label">B01</div>
  </div> -->

  <div class="front-cell-B01">
    <div class="front-cell-label">B01</div>
    <!-- <app-radar class="front-cell-row"></app-radar> -->
    <!-- <app-overview class="front-cell-overview"></app-overview> -->
    <!-- <app-start class="front-cell-main"></app-start> -->
    <div class="front-cell-main">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- <div class="front-cell C01">
    <div class="front-cell-label">C01 </div>
    <app-svg-slide class="front-cell-wrap"></app-svg-slide>
  </div> -->

  <!-- <div class="front-cell C02">
    <div class="front-cell-label">C02 </div>
  </div> -->

  <!-- <div class="front-cell C03">
    <div class="front-cell-label">C03 </div>
    <app-progress-bar class="front-cell-wrap"></app-progress-bar>
  </div> -->

</div>
