<div class="dialog-container" fxLayout="column">
  <div class="dialog-icons">
    <svg class="dialog-icons link" data-name="icon-help" matTooltip="help" matTooltipClass="tooltip-button-small"
      [matTooltipShowDelay]="500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.4 22.4">
      <path d="M0,0H24V24H0Z" style="fill:none" />
      <path d="M12,2c-12.7.4-13.08,19.17,0,20C24.7,21.58,25.08,2.81,12,2Z" />
      <path
        d="M10.87,13.9c-.35,0-.49-.09-.55-.44L10,11.61c-.06-.36.11-.53.46-.57l2.79-.39c.28-.05.37-.16.37-.44a.34.34,0,0,0-.37-.39H9.41c-.35,0-.51-.14-.51-.5V7.62c0-.35.16-.5.51-.5h4.52c1.82,0,2.9.87,2.9,2.46v1.47c0,1.57-.84,2.15-2.17,2.33Zm-.34,3.3c-.37,0-.5-.15-.5-.5V14.86c0-.36.13-.5.5-.5h2.56c.35,0,.51.14.51.5V16.7c0,.35-.16.5-.51.5Z"
        style="fill:#fff" />
    </svg>
    <svg class="dialog-icons link" data-name="icon-close" matTooltip="close" (click)="close($event)"
      matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.4 22.4">
      <path opacity=".87" fill="none" d="M0 0h24v24H0V0z" />
      <path
        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" />
    </svg>
  </div>
  <div [ngSwitch]="this.data.titleColor" class="dialog-title">
    <div *ngSwitchCase="'base'" class="dialog-title" style="color:var(--appcolor-base)">{{data.title}}</div>
    <div *ngSwitchCase="'base-dark'" class="dialog-title" style="color:var(--appcolor-base-dark)">{{data.title}}</div>
    <div *ngSwitchCase="'green'" class="dialog-title" style="color:var(--appcolor-success)">{{data.title}}</div>
    <div *ngSwitchCase="'red'" class="dialog-title" style="color:var(--appcolor-danger)">{{data.title}}</div>
    <div *ngSwitchCase="'grey'" class="dialog-title" style="color:var(--appcolor-secondary)">{{data.title}}</div>
  </div>
  <div class="dialog-line"></div>
  <form>
    <div class="dialog-content">
      <!-- boite de dialogue -->
      <div class="dialog-text">
        <p *ngIf="data.text01">{{data.text01}}</p>
        <p *ngIf="data.text02">{{data.text02}}</p>
        <p *ngIf="data.text03">{{data.text03}}</p>
      </div>
      <div *ngIf="data.fields.f01.ondom" class="dialog-fieldset">
        <label class="label-pre">{{data.fields.f01.label}} :</label>
        <input type="text" placeholder="{{data.fields.f01.placeholder}}" #input1 />
      </div>
      <div *ngIf="data.fields.f02.ondom" class="dialog-fieldset">
        <label class="label-pre">{{data.fields.f02.label}} :</label>
        <input type="text" placeholder="test" #input1 />
      </div>
      <div *ngIf="data.fields.f03.ondom" class="dialog-fieldset">
        <label class="label-pre">{{data.fields.f03.label}} :</label>
        <select name="le_nom" id="le_nom" #select1>
          <option value="ta_valeur">Le nom qui apparaitra</option>
          <option value="ta_valeur">Le nom qui apparaitra</option>
          <option value="ta_valeur">Le nom qui apparaitra</option>
          <option value="ta_valeur">Le nom qui apparaitra</option>
        </select>
      </div>
    </div>
    <div class="dialog-line"></div>
    <div class="dialog-footer" fxLayoutGap="15px">
      <button *ngIf="data.buttons.b03.ondom" class="dialog-button" disabled={{!data.buttons.b03.isactive}}
        mat-raised-button (click)="close($event)">{{data.buttons.b03.caption}}</button>
      <button *ngIf="data.buttons.b02.ondom" class="dialog-button" disabled={{!data.buttons.b02.isactive}}
        mat-raised-button (click)="discard(null)">{{data.buttons.b02.caption}}</button>
      <button *ngIf="data.buttons.b01.ondom" class="dialog-button" disabled={{!data.buttons.b01.isactive}}
        mat-raised-button (click)="close($event)">{{data.buttons.b01.caption}}</button>
    </div>
  </form>
</div>
