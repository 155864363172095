import { AfterViewInit, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { terminalConsoleSelector } from '../../shared/store/terminal-store/terminal.selectors';
import { TerminalService } from 'src/app/shared/services/terminal.service';
import { State } from 'src/app/shared/store';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: [
    '../../shared/css/app.component.css',
    './terminal.component.css'
  ]
})
export class TerminalComponent implements OnInit, AfterViewInit {
  public menuTitle: string
  public menuSubMenu: [];

  @ViewChildren('menus', { read: ElementRef }) menus: QueryList<ElementRef>;

  constructor(
    private renderer: Renderer2,
    private terminalService: TerminalService,
    private router: Router,
    private store: Store<State>,
    ) {
    terminalService.renderer = renderer;
  }

  ngOnInit(): void {
    this.terminalService.setTerminalMessage('test');
    let menuDatas = this.terminalService.setTerminalMenuArray('main');
    this.menuTitle = menuDatas.terminalMenuObjectTitle;
    this.menuSubMenu = menuDatas.terminalMenuArray;
  }

  ngAfterViewInit(): void {
    this.terminalService.setTerminalMenusElementRef({ terminalMenus: this.menus });
    this.router.navigate(['home/terminal/terminaldash']);
  }

}


