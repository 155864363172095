import {
  formsActions,
  IS_DIRTY,
  SET_CUR_CTRL,
  SET_PREV_CTRL
} from './forms.actions';

import { FormsState } from '../../models/stateIndex.model'

const initialState = {
  isdirty: false,
  curctrl: null,
  prevctrl: null
};

export function formsReducer(state: FormsState = initialState, action: formsActions) {
  switch (action.type) {
    case IS_DIRTY: {
      return {
        ...state,
        isdirty: action.payload
      };
    }
    case SET_CUR_CTRL: {
      return {
        ...state,
        curctrl: action.payload
      };
    }
    case SET_PREV_CTRL: {
      return {
        ...state,
        prevctrl: action.payload
      };
    }
  }
  return state;
}
