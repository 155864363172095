export default {
  saveChanges: {
    width: '290px',
    disableClose: true,
    autoFocus: true,
    panelClass: 'dlab-dialog',
    data: {
      title: 'unsaved Changes',
      titleColor: 'green',
      text01: 'Are you sure you want to leave this page ?',
      text02: 'you have pending unsaved changes. Do you realy want to discard them ?',
      text03: null,
      fields: {
        f01: {
          label: 'premier champs de formulaire',
          placeholder: 'placeholder du premier champ',
          ondom: false,
          class: null,
        },
        f02: {
          label: 'leave this page',
          placeholder: 'console.log(datainput)',
          ondom: false,
          class: null,
        },
        f03: {
          label: 'ahhahahahahah',
          placeholder: null,
          ondom: false,
          class: null,
        }
      },
      buttons: {
        b01: {
          caption: 'cancel',
          ondom: true,
          isactive: true,
          class: null,
          onclick: 'consollogtest()',
        },
        b02: {
          caption: 'discard',
          ondom: true,
          isactive: true,
          class: null,
          onclick: 'console.log("discard")',
        },
        b03: {
          caption: null,
          ondom: false,
          isactive: false,
          class: null,
          onclick: null,
        }
      }
    }
  },

};
