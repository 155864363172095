import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { IsNotDirtyGuard } from '../guards/isnotdirty.guard';
import { FrontComponent } from '../../front/front.component';
import { StartComponent } from '../components/start/start.component';
import { OverviewComponent } from 'src/app/front/overview/overview.component';
import { SigninComponent } from '../../front/auth/signin/signin.component';
import { SignupComponent } from '../../front/auth/signup/signup.component';
import { ProfileComponent } from '../../front/users/profile/profile.component';
import { MediaComponent } from 'src/app/front/medias/media.component';
import { GenericComponent } from 'src/app/back/generic/generic.component';
import { TerminalComponent } from 'src/app/front/terminal/terminal.component';
import { CryptoComponent } from 'src/app/front/terminal/crypto/crypto.component';
import { TerminaldashComponent } from 'src/app/front/terminal/terminaldash/terminaldash.component';

const routes: Routes = [

  { path: '', pathMatch: 'full', redirectTo: '/overview' },

  {
    path: 'home', component: FrontComponent,
    children: [
      // { path: '', pathMatch: 'full', redirectTo: '/home' },
      { path: 'start', component: StartComponent },
      { path: 'signin', component: SigninComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'profile', canActivate: [AuthGuard], canDeactivate: [IsNotDirtyGuard], component: ProfileComponent },
      { path: 'medias', component: MediaComponent },
      { path: 'back', component: GenericComponent },
      {
        path: 'terminal', component: TerminalComponent,
        children: [
          { path: 'terminaldash', component: TerminaldashComponent },
          { path: 'crypto', component: CryptoComponent },

        ]
      },
    ]
  },
  { path: 'overview', component: OverviewComponent },

  // { path: '', pathMatch: 'full', loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule) },
  // { path: 'medias', loadChildren: () => import('../../medias/medias.module').then(m => m.MediasModule) },
  // { path: 'identity', loadChildren: './app/identity-container/identity.module#MediasModule' },
  // { path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class RoutingModule { }
