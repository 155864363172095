import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,

} from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { State } from './shared/store';
import { SetCurrentApiPath } from './shared/store/temp-store/temp.actions';
import { TryFetchCurrentUser } from './shared/store/auth-store/auth.actions';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { SnackBarComponent } from './shared/components/snack-bar/snackbar.component';
import { environment } from '../environments/environment';
import { MetaService } from './shared/services/meta.service';
import { AlertService } from './shared/services/alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './shared/css/app.component.css'
  ],
})

/**
 * @title app.component
 * @description initial component
 */

export class AppComponent implements OnInit, OnDestroy {
  private cookieState: {};
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  private sub: Subscription;
  public mainLogo: ElementRef;

  constructor(
    public dialogComponent: DialogComponent,
    public snackBar: SnackBarComponent,
    public alertService: AlertService, // do not delete => launch of alertService
    public metaService: MetaService, // do not delete => launch of metaService
    private cookieService: CookieService,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.cookieState = this.cookieService.getAll();
    // console.log('->[ AppComponent/cookieState ]: ', this.cookieState);
    setTimeout(() => {
      this.alertService.success(this.cookieService.get('iasct-cake'), this.options);
      setTimeout(() => {
        this.alertService.success(this.cookieService.get('iasct-ip'), this.options);
      }, 500);
    }, 1000);
    // this.store.dispatch(new SetCurrentApiPath(environment.appApiPath));
    // this.store.dispatch(new TryFetchCurrentUser());
  }

  ngOnDestroy(): void {
    console.log('=>[ AppComponent/ngOnDestroy ]');
    this.sub.unsubscribe();
  }
}


