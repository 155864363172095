import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
// local imports
import { State } from '../../store';
import { User } from '../../models/user.model';
import { isLoggedinSelector, currentUserSelector } from '../../store/auth-store/auth.selectors';
import { Logout } from '../../store/auth-store/auth.actions';
import { FormsService } from '../../services/forms.service';

/**
* @title class IconMenuComponent
* @description Front Menu Component button to logg in and sign-up
*
*/
@Component({
  selector: 'app-iconmenu',
  templateUrl: './iconmenu.component.html',
  styleUrls: [
    '../../../shared/css/app.component.css',
    '../../../front/front.component.css',
    './iconmenu.component.css'
  ],
})

export class IconMenuComponent implements OnInit {
  public device: string;
  public isLoggedin$: Observable<boolean>;
  public currentUser$: Observable<User>;
  public isrouterEnabled$: Observable<boolean>;

  constructor(
    private store: Store<State>,
    private formsService: FormsService,
  ) { }

  ngOnInit(): void {
    this.isLoggedin$ = this.store.pipe(select(isLoggedinSelector));
    this.currentUser$ = this.store.pipe(select(currentUserSelector));
    this.isrouterEnabled$ = this.formsService.isformDirty$;
  }

  public logout(): void {
    this.store.dispatch(new Logout());
  }

}
