import { Params, RouterStateSnapshot, Data } from '@angular/router';
import { RouterStateSerializer, RouterReducerState } from '@ngrx/router-store';

export const routerStateConfig = { stateKey: 'routing' };

export interface MyRouterState {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

export type MyRouterReducerState = RouterReducerState<MyRouterState>;

export class MyRouterStateSerializer
  implements RouterStateSerializer<MyRouterState> {
  serialize(routerState: RouterStateSnapshot): MyRouterState {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }
    const { url, root: { queryParams } } = routerState;
    const { params } = route;
    const { data } = routerState.root;
    return { url, params, queryParams, data };
  }
}
