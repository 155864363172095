import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  radius = 30;
  circumference = 2 * Math.PI * this.radius;
  dashoffset: number;
  value: number;

  constructor() { }

  ngOnInit(): void {
    this.progress(1, 100);
  }

  private progress(i: number, value: number) {
    setTimeout(() => {
      let progress = i / 100;
      this.dashoffset = this.circumference * (1 - progress);
      // console.log('=>[ ProgressBarComponent/progress ]', i, " / ", progress, " ", this.dashoffset);
      this.value = i;
      if (i < value) {
        this.progress(i + 1, value);
      }
    }, 10);
  }

}
