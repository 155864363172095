import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
// local imports
import { State } from '../store';
import { TempState } from '../models/stateIndex.model';
import { tempStateRoot, apiPath, isRouterEnabled, nextUrl } from '../store/temp-store/temp.selectors';
import { IsRouterEnabled, SetCurrentApiPath, SetRouterNextUrl } from '../store/temp-store/temp.actions';

@Injectable({
  providedIn: 'root'
})
export class TempService {
  private tempState: TempState;
  public apiPath: string;
  public nextUrl: string;

  constructor(
    private store: Store<State>,
    private router: Router,
  ) { }

  public getTempState(): TempState {
    this.store.pipe(first(), select(tempStateRoot)).subscribe((state: any) => {
      this.tempState = state;
    });
    return this.tempState;
  }

  public getApiPath(): Observable<string> {
    return this.store.pipe(select(apiPath));
    // return this.apiPath;
  }

  public getIsRouterEnabled(): Observable<boolean> {
    return this.store.pipe(first(), select(isRouterEnabled));
    // return this.isRouterEnabled;
  }

  public getNextUrl(): string { return this.nextUrl; }

  public navigateNextUrl(): void {
    console.log('[ navigateNextUrl, ] ', this.nextUrl);
    if (this.nextUrl) {
      this.router.navigate([this.nextUrl]);
    }
  }

  public setApiPath(apiPath): void {
    this.store.dispatch(new SetCurrentApiPath(apiPath));
    this.apiPath = apiPath;
  }

  public setIsRouterEnabled(isRouterEnabled: boolean): void {
    this.store.dispatch(new IsRouterEnabled(isRouterEnabled));
  }

  public setNextUrl(nextUrl: string): void {
    this.store.dispatch(new SetRouterNextUrl(nextUrl));
    this.nextUrl = nextUrl;
  }
}
