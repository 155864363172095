import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Auth
import { AuthInterceptor } from '../store/auth-store/auth.interceptor';

// directives
import { ScrollDirective } from '../directives/scroll.directive';
// import { FormsDirective } from '../directives/forms.directive';

const COMPONENTS = [
  // FormsDirective
  ScrollDirective,
];

@NgModule({
  imports: [
    HttpClientModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
})

export class CoreModule { }
