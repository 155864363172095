import { SettingsTitle } from '../../models/settingsTitle.model';
import * as settingsTitleAction from './settings-title.actions';
import { SettingsTitleState } from '../../models/stateIndex.model';

const initialState = {
  st_id: null,
  title: null,
  st_citation: null,
  st_letter: null,
  st_corporate: null,
  st_female: null,
  st_plural: null,
  st_edi: null,
};

export function settingsTitleReducer(
  state: SettingsTitleState = initialState,
  action: settingsTitleAction.SettingsTitlesActionType): SettingsTitleState {
  switch (action.type) {
    case settingsTitleAction.FETCH_SETTINGS_TITLE_LIST:
      return {
        ...state,
      };
    default:
      return state;
  }
}
