<div  role="main">
  <!-- Resources -->
  <div >
    <h2>fiche profil</h2>
    <p>Veuillez vérifier vos informations :</p>
  </div>
  <div class="form-container">
    <form class="form" [formGroup]="form" autocomplete="off">
      <!-- icone de formulaire -->
      <div class="form-icon">
        <svg class="form-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <defs>
            <style>
              .cls-1 {
                fill: none;
              }

              .cls-2 {
                fill: #fff;
              }
            </style>
          </defs>
          <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
              <path class="cls-1" d="M0,0H24V24H0Z" />
              <path d="M12,2c-12.7.4-13.08,19.17,0,20C24.7,21.58,25.08,2.81,12,2Z" />
              <path class="cls-2"
                d="M10.87,13.9c-.35,0-.49-.09-.55-.44L10,11.61c-.06-.36.11-.53.46-.57l2.79-.39c.28-.05.37-.16.37-.44a.34.34,0,0,0-.37-.39H9.41c-.35,0-.51-.14-.51-.5V7.62c0-.35.16-.5.51-.5h4.52c1.82,0,2.9.87,2.9,2.46v1.47c0,1.57-.84,2.15-2.17,2.33Zm-.34,3.3c-.37,0-.5-.15-.5-.5V14.86c0-.36.13-.5.5-.5h2.56c.35,0,.51.14.51.5V16.7c0,.35-.16.5-.51.5Z" />
            </g>
          </g>
        </svg>
      </div>
      <div class="form-title">compte</div>
      <!-- contenu de formulaire -->
      <div class="form-content-column">
        <div class="form-content-row" fxLayoutGap="15px">
          <div class="form-fieldset w50">
            <label class="label-pre">initials</label>
            <input id="focus-00" formsDirective formControlName="initials" matInput type="text"
              placeholder="initials" />
            <div class="field-status-error"></div>
          </div>
          <div class="form-fieldset">
            <label class="label-pre">nickname</label>
            <input formsDirective formControlName="nickname" matInput type="text" placeholder="nickname" />
            <div class="field-status-error"></div>
          </div>
        </div>
        <div class="form-content-row" fxLayoutGap="15px">
          <!-- field firstname -->
          <div class="form-fieldset w50">
            <label class="label-pre">firstname</label>
            <input formsDirective formControlName="firstname" matInput type="text" placeholder="firstname" />
            <div class="field-status-error"></div>
          </div>
          <div class="form-fieldset">
            <label class="label-pre">
              <span *ngIf="!name.invalid" matTooltip="le nom est obligatoire.">name *</span>
              <span *ngIf="name.invalid && (name.dirty || name.touched)" class="text-red">
                <span *ngIf="name.errors['required']">Name is required. </span>
                <span *ngIf="name.errors['minlength']">Name must be at least 4 ch long. </span>
                <span *ngIf="name.errors['forbiddenName']">Name cannot be thomas. </span>
              </span>
            </label>
            <input formsDirective formControlName="name" matInput type="text" placeholder="name" />
            <div class="field-status-error"></div>
          </div>
        </div>
        <div class="form-content-row" fxLayoutGap="15px">
          <div class="form-fieldset">
            <label class="label-pre">password :</label>
            <input formsDirective formControlName="password" matInput type="text" placeholder="password" />
            <div class="field-status-error"></div>
          </div>
          <div class="form-fieldset">
            <label class="label-pre">password :</label>
            <input formsDirective formControlName="passwordconfirm" matInput type="password" placeholder="password" />
            <div class="field-status-error"></div>
          </div>
        </div>
        <div class="form-content-row" fxLayoutGap="15px">
          <div class="form-fieldset">
            <label class="label-pre">email :</label>
            <input formsDirective formControlName="email" matInput type="text" placeholder="email" />
            <div class="field-status-error"></div>
          </div>
          <div class="form-fieldset w25">
            <label class="label-pre">rôle</label>
            <input formsDirective formControlName="role" matInput type="text" placeholder="role" />
            <div class="field-status-error"></div>
          </div>
        </div>
      </div>
      <div *ngIf="error$ | async; let error">
        <div class="form-line"></div>
        <div class="form-footer-message">{{ error }}</div>
      </div>
      <div class="form-footer" fxLayoutGap="15px">
        <button class="button" mat-raised-button (click)="close()">fermer</button>
        <button class="button" mat-raised-button (click)="updateUser()" [disabled]="!(isButtonDisabled$ | async)">mettre
          à jour
        </button>
      </div>
    </form>
  </div>
</div>
