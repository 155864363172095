import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { State } from '../../app/shared/store';
import { LayoutService } from '../shared/services/layout.service';
import { ExplorerService } from '../shared/services/explorer.service';
import { DomeventsService } from '../shared/services/domevents.service';
import { OverviewService } from '../shared/services/overview.service';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: [
    '../shared/css/app.component.css',
    './front.component.css',
  ],
})

/**
 * @title frontComponent
 * @description frontEnd initial component load round progressbar and cursor
 */
export class FrontComponent implements OnInit, OnDestroy, AfterViewInit {
  radius = 54;
  circumference = 2 * Math.PI * this.radius;
  dashoffset: number;

  constructor(
    public overviewService: OverviewService,
    public layoutService: LayoutService,
    public explorerService: ExplorerService,
    public domeventsService: DomeventsService,
    private renderer: Renderer2,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.setFrontWrap();
    this.router.navigate(['/home/terminal/terminaldash']);
    // this.progress(10);
  }

  ngAfterViewInit(): void {

  }

  setFrontWrap(): void {
    this.layoutService.setFrontLayout();
  }

  private progress(value: number) {
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
  }

  ngOnDestroy(): void {
    console.log('=>[ FrontComponent/ngOnDestroy ]');
  }
}
