import { Action } from '@ngrx/store';

export const GET_ROUTER_URL = '[ router ] get router url';
export const GET_ROUTER_PARAMS = '[ router ] get router params';

export class GetRouterUrl implements Action {
  readonly type = GET_ROUTER_URL;
}

export class GetRouterParams implements Action {
  readonly type = GET_ROUTER_PARAMS;
}

export type routerActions = GetRouterUrl |
                            GetRouterParams;
