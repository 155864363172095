import { Injectable, Inject, QueryList, ElementRef, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { SetWindowSize } from '../store/temp-store/temp.actions';
import { WindowProp, ElementRefProp, SectionProp, VideoCardProp, CardProp, ScrollProp, MouseProp, VideoSectionProp } from '../models/layout.model';

/**
* @title class LayoutService
* @description functions for all layout (mise en page) behaviour
*/
@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public renderer: Renderer2;
  public device: BehaviorSubject<string> = new BehaviorSubject('web');
  public cardProp: CardProp = { width: 300, height: 300 };
  public mouseProp: MouseProp;
  public sectionProp: SectionProp = { count: 1, width: 0, height: 0 };
  public videoProp: VideoCardProp = { count: 5, width: 150, height: 200 };
  public windowProp: WindowProp;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private breakpointObserver: BreakpointObserver,
    private store: Store<State>,
  ) { }


  public setVideoProp(): VideoSectionProp {
    if (this.windowProp.width >= this.windowProp.height) {
      return { width: this.windowProp.width - 100, height: this.windowProp.height * (1 / this.windowProp.ratio) };
    } else {
      return { width: this.windowProp.width / this.windowProp.ratio, height: this.windowProp.height - 100 };
    }
  }

  /**
 * @title setViewChildrenBorder
 * @description get the parentElement of the vcTarget and increase borders till full hd format
 * @parameter vcTarget: QueryList
 * @return void
 */
  public setViewChildrenBorder({ vcTarget, flag }: { vcTarget: QueryList<ElementRef>; flag?: number }): void {
    vcTarget.forEach((key: any, index: any) => {
      let gap = window.innerWidth - Math.round(parseInt(getComputedStyle(key.nativeElement).getPropertyValue('height')) * (1920 / 1080) - 0);
      let parentBackgroundColor = this.getElementRefProp({ el: key.nativeElement.parentElement, flag: index }).color.border;
      if (gap > 0) {
        this.renderer.setStyle((key.nativeElement), 'border-left-color', parentBackgroundColor);
        this.renderer.setStyle((key.nativeElement), 'border-left-style', 'solid');
        this.renderer.setStyle((key.nativeElement), 'border-left-width', (gap / 2 + 0 + 'px'));
        this.renderer.setStyle((key.nativeElement), 'border-right-color', parentBackgroundColor);
        this.renderer.setStyle((key.nativeElement), 'border-right-style', 'solid');
        this.renderer.setStyle((key.nativeElement), 'border-right-width', (gap / 2 + 2 + 'px'));
      }
    });
  }

  /**
 * @title getElementRefProp
 * @description get the style of element ref sent
 * @parameter el : any elementRef
 * @return an ElementRefProp
 */
  public getElementRefProp({ el, flag }: { el: any; flag?: number }): ElementRefProp {
    return {
      width: Math.round(parseInt(getComputedStyle(el).getPropertyValue('width'))),
      height: Math.round(parseInt(getComputedStyle(el).getPropertyValue('height'))),
      color: {
        border: window.getComputedStyle(el).getPropertyValue('border-left-color'),
        background: window.getComputedStyle(el).getPropertyValue('background-color'),
        stroke: window.getComputedStyle(el).getPropertyValue('-webkit-text-stroke-color'),
        textcolor: window.getComputedStyle(el).getPropertyValue('color'),
      },
    }
  }

  // to delete unuseful
  public setViewChildrenSize({ vcTarget, flag }: { vcTarget: QueryList<ElementRef>; flag: number }) {
    vcTarget.forEach((key: any, index: any) => {
      switch (flag) {
        case 1: // set width
          this.renderer.setStyle(key.nativeElement, 'width', `${window.innerWidth}px`);
          break;
        case 2: // set height
          this.renderer.setStyle(key.nativeElement, 'height', `${window.innerHeight}px`);
          break;
        case 3: // set width or height depending on ratio
          console.log('=>[ OverviewService/setOverviewServiceViewDatas ]', this.windowProp);
          if (this.windowProp.ratio >= 1) {
            this.renderer.setStyle(key.nativeElement, 'height', `${window.innerHeight}px`);
          } else {
            this.renderer.setStyle(key.nativeElement, 'width', `${window.innerWidth}px`);
          }
          break;
      }
    });
  }

  public setFrontLayout(): void {
    this.setBreakpoint();
    this.setWindowProp();
    this.document.documentElement.style.setProperty('--appwindow-width', this.windowProp.width + 'px');
    this.document.documentElement.style.setProperty('--appwindow-height', this.windowProp.height + 'px');
    this.document.documentElement.style.setProperty('--appcard-width', this.cardProp.width + 'px');
    this.document.documentElement.style.setProperty('--appcard-height', this.cardProp.width + 'px');
    this.document.documentElement.style.setProperty('--appsection-width', this.sectionProp.width + 'px');
    this.document.documentElement.style.setProperty('--appsection-height', this.sectionProp.height + 'px');
  }

  public setBreakpoint(): void {
    this.breakpointObserver
      .observe([
        // Breakpoints.Handset,
        // Breakpoints.HandsetPortrait,
        Breakpoints.Tablet,
        // Breakpoints.Web,
        // '(max-width: 500px)',
        // '(min-width: 501px)'
      ])
      .subscribe((appSize) => {
        // console.log('=>[ LayoutService/setBreakpoint ] ', appSize);
        // const small = this.breakpointObserver.isMatched('(max-width: 500px)');
        // const big = this.breakpointObserver.isMatched('(min-width: 501px)');
        // if (small) { this.device.next('small'); }
        // if (big) { this.device.next('big'); }
      });
  }

  public setWindowProp(): void {
    this.windowProp = {
      width: window.innerWidth,
      height: window.innerHeight,
      ratio: (window.innerHeight / window.innerWidth),
      windowCenter: { top: (window.innerHeight / 2), left: (window.innerWidth / 2) }
    };
    this.store.dispatch(new SetWindowSize(this.windowProp));
  }
}
