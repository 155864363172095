  <div class="overview-wrap" (window:resize)="setOverviewWrap()" (mousemove)="domeventsService.detectMousePosition($event)"
  (wheel)="overviewService.scrollCatch($event)" (mousemove)="domeventsService.detectMousePosition($event)">
    <div class="overview-topbar">
      <div class="front-cell-label">A01</div>
      <app-idlogo class="front-cell-wrap"></app-idlogo>
    </div>
    <div class="overview-video-wrap">
      <video #videos class="overview-video-wrap" width="auto" height="{{videoProp.height}}" loop autoplay
        onloadedmetadata="this.muted = true" playsinline>
        <source src="../../../assets/medias/overview/video-agence-iasct-01.mp4" type="video/mp4">
        This browser does not support the HTML5 video element.
      </video>
    </div>
    <div class="overview-screens">
      <div class="overview-screen-background">
      </div>
      <div class="overview-screen-00" #screens id="screen00" data-index="0">
        <img #backImages class="" id="" src="../../../assets/img/svg/overview/screen-00-center-logo-01-outer.svg" alt="" />
      </div>
      <div class="overview-screen-01" #screens id='screen01' data-index="1">
        <video #videos class="tag-absolute-center backvideo-700" loop autoplay onloadedmetadata="this.muted = true"
          playsinline>
          <source src="../../../assets/medias/overview/Binary_numbers_bg_01_Videvo.mp4" type="video/mp4">
          This browser does not support the HTML5 video element.
        </video>
        <img class="" #backImages id="" src="../../../assets/img/svg/overview/screen-01-01.svg" alt="" />
      </div>
      <div #screens id="screen02" class="overview-screen-02" data-index="2">
        <img class="" #backImages id="" src="../../../assets/img/svg/overview/screen-02-01.svg" alt="" />
      </div>
      <div #screens id="screen03" class="overview-screen-03" data-index="3">
        <img class="" #backImages id="" src="../../../assets/img/svg/overview/screen-03-01.svg" alt="" />
      </div>
    </div>
    <div class="overview-direct-links-wrap">
      <div class="overview-direct-links-button-wrap">
        <div *ngFor="let link of linksDatas; let i=index" class="overview-direct-links-button"
          attr.data-text="{{link}}" attr.id="link{{i}}" attr.data-index={{i}}>
          <div class="overview-direct-links-letter-wrap">
            <span *ngFor="let letter of link.split(''); let ii=index" #links class="overview-direct-links-letter-even"
              attr.data-link="{{i}}" attr.id="letterlink.{{i}}.{{ii}}"
              (click)="overviewService.linksClick($event)">{{letter}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
