<div class="signin-wrap">
  <div class="form-wrap">
    <div class="form-header">
      <h1>Authentification</h1>
      <p>Veuillez saisir vos identifiants :</p>
    </div>
    <form class="form-content" [formGroup]="form">
      <div class="form-title">Connexion par mail</div>
      <div class="form-content-column">
        <div class="form-fieldset">
          <label class="label-pre">e-mail :</label>
          <input formControlName="email" matInput type="text" placeholder="E-Mail" />
          <div class="field-status-error"></div>
        </div>
        <div class="form-fieldset">
          <label class="label-pre">password :</label>
          <input formControlName="password" matInput type="password" placeholder="Password" />
          <div class="field-status-error"></div>
        </div>
      </div>
      <!-- form message -->
      <div *ngIf="error$ | async; let error">
        <!-- form footer -->
        <!-- <div class="form-footer-message">{{ error }}</div> -->
      </div>
      <div class="form-footer" fxLayoutGap="35px">
        <button class="button" mat-raised-button (touchstart)="submit()" (click)="submit()"
          color="primary">Connexion</button>
      </div>
    </form>
  </div>
</div>
