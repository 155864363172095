import { Component, OnInit } from '@angular/core';
import { TerminalService } from 'src/app/shared/services/terminal.service';

@Component({
  selector: 'app-crypto',
  templateUrl: './crypto.component.html',
  styleUrls: [
    '../../../shared/css/app.component.css',
    './crypto.component.css'
  ]
})
export class CryptoComponent implements OnInit {
  public menuTitle: string
  public menuSubMenu = [];

  constructor(
    private terminalService: TerminalService,
  ) {}

  ngOnInit(): void {
    // let menuDatas = this.terminalService.setTerminalMenuArray('crypto');
    // console.log('=>[ TerminalComponent / menuDatas ]', menuDatas);
    // this.menuTitle = menuDatas.terminalMenuObjectTitle;
    // this.menuSubMenu = menuDatas.terminalMenuArray;
  }

}
