<div (window:resize)="setVideoWrapper()" fxLayout="column" fxLayoutAlign="center center">
  <!-- <mat-progress-spinner class="spinner" mode="determinate" diameter="50" customSpinner value="70"></mat-progress-spinner> -->

  <video #video01 class="front-video" src="../../../assets/medias/headerwawe2.mp4" width="{{headerVideoWidth}}" height="{{headerVideoHeight}}" autoplay="" loop="" muted="" playsinline="" >
    <!-- <source src="../../../assets/medias/showreeltb.mp4?autoplay=1" type="video/mp4"> -->
  </video>
  <img #mainLogo class="mainlogo" id="iasctmainlogo" src="../../../../assets/img/logo iasct transparance _poster_white_.png"
    alt="iasct">
  <div >
    <h1>Little Big Cam productions</h1>
    <h2>capturez, racontez, diffusez</h2>
  </div>

</div>
