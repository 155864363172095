<div class="terminaldash-wrap">

  <div class="terminaldash-cell-A01">
    <div class="terminaldash-cell-label">TDA01</div>
  </div>

  <div class="terminaldash-cell-A02">
    <div class="terminaldash-cell-label">TDA02</div>
    <div class="terminaldash-message">
      {{terminalMessage$ | async}}
    </div>
  </div>

  <div class="terminaldash-cell-B01">
    <div class="terminaldash-cell-label">TDB01</div>
  </div>

  <div class="terminaldash-cell-B02">
    <div class="terminaldash-cell-label">TDB02</div>
  </div>

  <div class="terminaldash-cell-C01">
    <div class="terminaldash-cell-label">TDC01</div>

  </div>

  <div class="terminaldash-cell-C02">
    <div class="terminaldash-cell-label">TDC02</div>
  </div>

</div>
