import { Injectable, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
// local imports
import metaData from '../content/meta-datas';

@Injectable({
  providedIn: 'root'
})
export class MetaService implements OnDestroy {
  private sub = new Subscription();

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title
  ) {
    this.sub.add(
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          const url = event.urlAfterRedirects;
          if (metaData[url]) {
            this.updateTitle(url);
            this.updateMetas(url);
          } else {
            this.updateTitle('/home');
            this.updateMetas('/home');
          }
        }
      })
    );
  }

  private updateTitle(url: string): void {
    this.title.setTitle(metaData[url].title);
  }

  private updateMetas(url: string): void {
    const oldTagDescription = this.meta.getTag('name="description"');
    const newTagDescription = {
      name: 'description',
      content: metaData[url].metas.description
    };
    const oldTagOgTitle = this.meta.getTag('name="og:title"');
    const newTagOgTitle = {
      name: 'og:title',
      content: metaData[url].metas['og:title']
    };

    oldTagDescription
      ? this.meta.updateTag(newTagDescription)
      : this.meta.addTag(newTagDescription);
    oldTagOgTitle
      ? this.meta.updateTag(newTagOgTitle)
      : this.meta.addTag(newTagOgTitle);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
