import { ElementRef, Injectable, QueryList, Renderer2 } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { catchError, map, tap, switchMap, filter, take } from 'rxjs/operators';
import { State } from '../store';
import mainJson from './../content/terminalmenu.json';
import { SetTerminalStateConsole, SetTerminalStateError, SetTerminalStateTime, SetTerminalStateMessage } from '../store/terminal-store/terminal.actions';
import { terminalMessageSelector } from '../store/terminal-store/terminal.selectors';

@Injectable({
  providedIn: 'root'
})
export class TerminalService {
  public renderer: Renderer2;
  private terminalMenuArray = [];
  private terminalMenuObject: {};
  private terminalMenuObjectTitle: string;

  private terminalMenusElementRef: {};

  public terminalMessage$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private store: Store<State>,

  ) { }

  public setTerminalMessage(message: string): void {
    this.store.dispatch(new SetTerminalStateMessage(message));
  }

  public getTerminalMessage(): Observable<string> {
    return this.store.pipe(select(terminalMessageSelector));
  }

  public setTerminalMenusElementRef({ terminalMenus, flag }: { terminalMenus: QueryList<ElementRef>; flag?: number }): {} {
    let arr = [];
    for (let el of terminalMenus) {
      arr.push([el.nativeElement.dataset['index'], el.nativeElement.dataset['text']]);
    };
    this.terminalMenusElementRef = arr;
    console.log('=>[ TerminalService/terminalMenusElementRef ]', this.terminalMenusElementRef);
    return this.terminalMenusElementRef;
  }

  public setTerminalConsole(): void {
    console.log('=>[ TerminalService/setconsole ]', this.terminalMenusElementRef);
    this.store.dispatch(new SetTerminalStateConsole({ tab: [] }));
  }

  /**
   * Sets terminal menu array
   * @param menuid
   * @returns terminal menu array
   */
  public setTerminalMenuArray(menuid: string): { terminalMenuObjectTitle: string; terminalMenuArray: any } {
    this.terminalMenuArray = [];
    if (menuid === 'main') {
      this.terminalMenuObject = mainJson.main.menu;
      this.terminalMenuObjectTitle = mainJson.main.title;
    } else {

    };
    for (const submenu of Object.values(this.terminalMenuObject)) {
      this.terminalMenuArray.push(submenu)
    }
    this.terminalMenuArray.sort((a: any, b: any) => { return a.index - b.index; });
    return { terminalMenuObjectTitle: this.terminalMenuObjectTitle, terminalMenuArray: this.terminalMenuArray };
  }

}
