import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

import { User } from '../../../shared/models/user.model';
import { forbiddenNameValidator, checkPasswordValidator, UniqueEmailValidator } from 'src/app/shared/directives/validators.directive';
import { IsDirty } from 'src/app/shared/guards/isnotdirty.guard';
import { UserService } from '../../../shared/services/user.service';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snackbar.component';
import { FormsService } from 'src/app/shared/services/forms.service';
import { TempService } from 'src/app/shared/services/temp.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/shared/store';
import { UpdateCurrentUser } from 'src/app/shared/store/auth-store/auth.actions';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: [
    '../../../shared/css/app.component.css'
  ]
})

export class ProfileComponent implements OnInit, OnDestroy, IsDirty {
  public form: FormGroup;
  public error$: Observable<string>;
  public currentUser: User;
  public isRouterEnabled$ = new BehaviorSubject(true);
  public isButtonDisabled$ = new BehaviorSubject(true);

  constructor(
    public dialogComponent: DialogComponent,
    private fb: FormBuilder,
    private emailAsyncValidator: UniqueEmailValidator,
    private tempService: TempService,
    private userService: UserService,
    private formsService: FormsService,
    private router: Router,
    private snackBar: SnackBarComponent,
    private store: Store<State>,
    ) {

  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUserState();
    this.createUserForm();
    this.formsService.onChanges(this.form);
    this.isButtonDisabled$ = this.formsService.isformDirty$;
  }

  ngOnDestroy(): void {
    // this.sub.unsubscribe();
    // this.tempService.setIsRouterEnabled(true);
  }

  isFormDirty(): boolean {
    this.isRouterEnabled$.next(!this.formsService.isformDirty$.value);
    if (!this.isRouterEnabled$.value) {
      this.dialogComponent.openDialog();
    }
    return this.isRouterEnabled$.value;
  }

  public close(): void {
    this.router.navigate(['/']);
  }

  public updateUser(): void {
    console.log('[ SignupComponent/test ]: ', this.form.value);
    if (this.form.invalid) {
      console.log('[ updateUser ]', this.form);
      this.snackBar.openSnackBarDanger('formulaire non valide', 'corrigez');
      // this.alertService.error('formulaire non valide!!', null);
      return;
    } else {
      this.snackBar.openSnackBarInfos('enregistrement validé', 'x');
      this.store.dispatch(new UpdateCurrentUser(this.form.value));
      // this.snackBar.openSnackBar('enregistrement validé', 'saving');
      this.userService.updateCurrentUser(this.form.value).pipe(
        first(),
      ).subscribe();
      this.formsService.canDeactivate();
      this.tempService.navigateNextUrl();
      return;
    }

  }

  getControlsValidatorErrors(): void {
    const fields = (Object.keys(this.form.controls));
    const fieldsWithValidators = [];
    console.log(fields);
    for (const field of fields) {
      console.log(field);
      if (this.form.controls[field].validator) {
        fieldsWithValidators.push(field);
      }
    }
    console.log(fieldsWithValidators);
    const fieldsWithValidatorsErrors = [];
    for (const fieldWithValidators of fieldsWithValidators) {
      console.log(fieldWithValidators);
      if (this.form.controls[fieldWithValidators].errors) {
        fieldsWithValidatorsErrors.push([fieldWithValidators, this.form.controls[fieldWithValidators].errors]);
      }
    }
    console.log(fieldsWithValidatorsErrors);
  }

  createUserForm(): void {
    this.form = this.fb.group({
      id: new FormControl(this.currentUser.us_id),
      identity_id: new FormControl(this.currentUser.us_identity_id),
      nickname: new FormControl(this.currentUser.us_nickname),
      initials: new FormControl(this.currentUser.us_initials),
      firstname: new FormControl(this.currentUser.us_firstname),
      name: new FormControl(this.currentUser.us_name, [
        Validators.required,
        Validators.minLength(4),
        forbiddenNameValidator(/bob/i)
      ]),
      email: new FormControl(this.currentUser.us_email, [
        Validators.required,
        Validators.email], [
        this.emailAsyncValidator.emailValidator(this.currentUser.us_email)
      ]),
      password: new FormControl(null, [
        // Validators.minLength(4)
      ]),
      passwordconfirm: new FormControl(null, [
        // Validators.required,
        // checkPasswordValidator()
      ]),
      role: new FormControl(this.currentUser.us_role),
    }, {
      validator: checkPasswordValidator('password', 'passwordconfirm')
    });
  }

  get name(): AbstractControl { return this.form.get('name'); }
  get email(): AbstractControl { return this.form.get('email'); }
  // get password() { return this.form.get('password'); }
  // get passwordconfirm() { return this.form.get('passwordconfirm'); }

}
