import { Action } from '@ngrx/store';
import { ControlState } from '../../models/stateIndex.model';

export const IS_DIRTY = '[ forms ] is current form dirty';
export const SET_CUR_CTRL = '[ forms ] set current control';
export const SET_PREV_CTRL = '[ forms ] set previous control';

export class IsDirty implements Action {
  readonly type = IS_DIRTY;
  constructor(public payload: boolean) { }
}

export class SetCurCtrl implements Action {
  readonly type = SET_CUR_CTRL;
  constructor(public payload: ControlState) { }
}

export class SetPrevCtrl implements Action {
  readonly type = SET_PREV_CTRL;
  constructor(public payload: ControlState) { }
}

export type formsActions =
  IsDirty |
  SetCurCtrl|
  SetPrevCtrl;
