// core imports
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
// local imports
import { State } from '../store';
import { HttpResponse } from '../models/httpResponse.model';
import { User } from '../models/user.model';
import { JwtToken } from '../models/jwtToken.model';
import { TryRefreshToken } from '../store/auth-store/auth.actions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public baseUrl: any;
  public currentUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  public jwtToken$: BehaviorSubject<JwtToken> = new BehaviorSubject({
    isAuthenticated: null,
    token: null
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<State>,
    // private tempService: TempService
  ) {
    // console.log('=>[ AuthService/constructor ]');
  }

  private initToken(): void {
    const token = localStorage.getItem('jwt');
    if (token) {
      this.jwtToken$.next({
        isAuthenticated: true,
        token
      });
    } else {
      this.jwtToken$.next({
        isAuthenticated: false,
        token: null
      });
    }
  }

  public setCurrentUser(user: User): void {
    this.currentUser$.next(user);
  }

  public signin(credentials: { email: string, password: string }): Observable<HttpResponse> {
    // console.log('[ AuthService/signin ]');
    return this.http.post<HttpResponse>(`${environment.appApiPath}/objects/auth/signin.php`, JSON.stringify(credentials));
  }

  public signup(user: User): Observable<User> {
    return this.http.post<User>(`${environment.appApiPath}/objects/auth/signup.php`, JSON.stringify(user));
  }

  public initRefreshToken(): Observable<number | HttpResponse> {
    return timer(2000, 5000).pipe(
      tap(() => {
        // console.log('[ service ]: initRefreshToken');
        this.store.dispatch(new TryRefreshToken());
      })
    );
  }

  public refreshToken(): Observable<string> {
    // console.log('[ service ]: refreshToken');
    return this.http.get<string>(`${environment.appApiPath}/objects/auth/validate_token.php`);
  }

  public logout(): void {
    this.jwtToken$.next({
      isAuthenticated: false,
      token: null
    });
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
