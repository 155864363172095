<div class="crypto-wrap">
  <div class="crypto-cell-A01">
    <div class="crypto-cell-label">A01</div>

  </div>

  <div class="crypto-cell-A02">
    <div class="crypto-cell-label">A02</div>
  </div>

  <div class="crypto-cell-B02">
    <div class="crypto-cell-label">B02</div>
    <div class="crypto-cell-main">
    </div>
  </div>

</div>

<router-outlet></router-outlet>
