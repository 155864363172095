import { Action } from '@ngrx/store';
import { User } from '../../models/user.model';

export const USER_CREATE = '[user] create';
export const USER_DELETE = '[user] delete';
export const USER_UPDATE = '[user] update';
export const USER_UPDATE_ERROR = '[user] update';
export const USER_TOGGLE = '[user] toggle';
export const FETCH_USER = '[user] fetch';
export const FETCH_USER_SUCCESS = '[user] fetch success';
export const FETCH_USER_ERROR = '[user] fetch error';

export class CreateUser implements Action {
  readonly type = USER_CREATE;
  constructor(public payload: User) {}
}

export class DeleteUser implements Action {
  readonly type = USER_DELETE;
  constructor(public payload: number) {}
}

export class UpdateUser implements Action {
  readonly type = USER_UPDATE;
  constructor(public payload: User) {}
}

export class UpdateUserError implements Action {
  readonly type = USER_UPDATE_ERROR;
  constructor(public payload: any) {}
}

export class ToggleUser implements Action {
  readonly type = USER_TOGGLE;
  constructor(public payload: number) {}
}

export class FetchUser implements Action {
  readonly type = FETCH_USER;
}

export class FetchUserSuccess implements Action {
  readonly type = FETCH_USER_SUCCESS;
  constructor(public payload: User[]) {}
}

export class FetchUserError implements Action {
  readonly type = FETCH_USER_ERROR;
  constructor(public payload: any) {}
}

export type UsersActionType = CreateUser |
                              DeleteUser |
                              UpdateUser |
                              UpdateUserError |
                              ToggleUser |
                              FetchUser |
                              FetchUserSuccess |
                              FetchUserError;
