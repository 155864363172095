import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, SimpleSnackBar } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

/**
 * @title snack-bar
 */
@Component({
  selector: 'app-snackbar',
  templateUrl: 'snackbar.component.html'
  // styleUrls: ['snackbar.component.css'],
})

export class SnackBarComponent {
  constructor(
    private snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<SimpleSnackBar>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) { }

  openSnackBarSuccess(message: string, action: string) {
    this.snackBarRef = this.snackBar.open(message, action, { panelClass: ['snack-bar-success'] });
  }

  openSnackBarDanger(message: string, action: string) {
    this.snackBarRef = this.snackBar.open(message, action, { panelClass: ['snack-bar-danger'] });
  }

  openSnackBarWarning(message: string, action: string) {
    this.snackBarRef = this.snackBar.open(message, action, { panelClass: ['snack-bar-warning'] });
  }

  openSnackBarInfos(message: string, action: string) {
    this.snackBarRef = this.snackBar.open(message, action, { panelClass: ['snack-bar-infos'] });
  }
}
