import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FileList } from '../models/explorer.model';
import { HttpResponse } from '../models/httpResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ExplorerService {

  constructor(
    private http: HttpClient,
  ) {
    // console.log('=>[ ExplorerService/constructor ]');
  }

  public getFileList(targetPath: string): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(`${environment.appApiPath}/objects/explorer/explorer_get.php`,
      { path: targetPath });
  }

  // public getFileList(targetPath: string): Observable<HttpResponse> {
  //   return this.http.post<HttpResponse>(`${environment.appApiPath}/objects/media/media_populate.php`,
  //     { path: targetPath });
  // }

}
