import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { first } from 'rxjs/operators';
// local imports
import { State } from '../store';
import { HttpResponse } from '../models/httpResponse.model';
import { currentUserSelector } from '../store/auth-store/auth.selectors';
import { User } from '../models/user.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public currentUserState: User;

  constructor(
    private http: HttpClient,
    private store: Store<State>,
  ) { }

  public getCurrentUser(): Observable<HttpResponse> {
    return this.http.get<HttpResponse>(`${environment.appApiPath}/objects/user/user_get.php`);
  }

  public updateCurrentUser(user: User): Observable<User> {
    // console.log('[ UserService, updateCurrentUser ]', user);
    return this.http.post<User>(`${environment.appApiPath}/objects/user/user_update.php`, JSON.stringify(user));
  }

  public getCurrentUserState(): User {
    this.store.pipe(first(), select(currentUserSelector)).subscribe((state: any) => {
      this.currentUserState = state;
    });
    return this.currentUserState;
  }

}

