import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../models/dialogData.model';
import dialogDatas from '../../content/dialog-datas';
// import { TempService } from '../../services/temp.service';
import { FormsService } from '../../services/forms.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})

export class DialogComponent {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogComponent>,
    // private tempService: TempService,
    private formsService: FormsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  discard(dialogOutput: []): void {
    this.dialogRef.close();
    this.formsService.isformDirty$.next(false);
    // this.tempService.setIsRouterEnabled(true);
    // this.tempService.navigateNextUrl();
  }

  close(dialogOutput: []): void {
    this.dialogRef.close();
  }

  public openDialog(): void {
    this.dialogRef = this.dialog.open(DialogComponent, dialogDatas.saveChanges);
    this.dialogRef.afterClosed().subscribe(dialogOutput => {
      // console.log('[ DialogComponent, openDialog, afterClosed ] ', this.tempService.getNextUrl());
    });
  }

}


