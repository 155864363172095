import {
  tempActions,
  SET_CURRENT_APIPATH,
  IS_ROUTER_ENABLED,
  SET_ROUTER_NEXTURL,
  SET_WINDOW_SIZE
} from './temp.actions';
import { TempState } from '../../models/stateIndex.model';

const initialState = {
  apiPath: null,
  isRouterEnabled: true,
  nexturl: null,
  windowSize: {width: null, height: null, ratio: null, windowCenter: null}
};

export function tempReducer(state: TempState = initialState, action: tempActions) {
  switch (action.type) {
    case SET_CURRENT_APIPATH: {
      return {
        ...state,
        apiPath: action.payload
      };
    }
    case IS_ROUTER_ENABLED: {
      return {
        ...state,
        isRouterEnabled: action.payload
      };
    }
    case SET_ROUTER_NEXTURL: {
      return {
        ...state,
        nexturl: action.payload
      };
    }
    case SET_WINDOW_SIZE: {
      return {
        ...state,
        windowSize: action.payload
      };
    }
  }
  return state;
}
