import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
@Injectable(
  { providedIn: 'root' }
)
export class FormsService {
  public isformDirty$ = new BehaviorSubject(false);

  constructor() {}

  onChanges(form: FormGroup): void {
    form.valueChanges.subscribe(val => {
      // console.log('[ FormsService, onChanges, valueChanges.subscribe ]', val)
      if (val) {
        this.isformDirty$.next(true);
        // this.tempService.setIsRouterEnabled(false);
        window.onbeforeunload = function (e) {
          var e = e || window.event
          // For IE and Firefox
          if (e) {
            // console.log('[ FormsService, onChanges, e ]', e)
            e.returnValue = 'Any string';
          }
          // For Safari
          return 'Any string';
        };
      }
    });
  }

  // authorize the deactivation of a form
  canDeactivate() {
    this.isformDirty$.next(false);
    window.onbeforeunload = null;
  }
}
