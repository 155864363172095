import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MyRouterState, routerStateConfig } from './router.serializer';

export const myRouterStateRoot = createFeatureSelector(routerStateConfig.stateKey);

export const myRouterState = createSelector(myRouterStateRoot,
  (routerState: MyRouterState) => {
    if (routerState) {
      return routerState;
    } else {
      return null;
    }
  });

  export const myRouterStateUrl = createSelector(myRouterStateRoot,
    (routerState: MyRouterState) => {
      if (routerState) {
        return routerState.url;
      } else {
        return null;
      }
    }
  );
