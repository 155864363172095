import { ActionReducerMap } from '@ngrx/store';
import * as routerReducer from '@ngrx/router-store';
import { MyRouterState } from './router-store/router.serializer';
import { TerminalState, AuthState, UserState, TempState, FormsState } from './../models/stateIndex.model';
import { terminalReducer } from './terminal-store/terminal.reducer';
import { authReducer } from './auth-store/auth.reducer';
import { usersReducer } from './users-store/users.reducer';
import { tempReducer } from './temp-store/temp.reducer';
import { formsReducer } from './forms-store/forms.reducer';
import { SettingsTitle } from '../models/settingsTitle.model';
import { settingsTitleReducer } from './settings-title-store/settings-title.reducer';

export interface State {
  terminal: TerminalState;
  auth: AuthState;
  forms: FormsState;
  routing: routerReducer.RouterReducerState<MyRouterState>;
  settingsTitle: SettingsTitle;
  users: UserState;
  temp: TempState;
}

export const reducersMap: ActionReducerMap<State> = {
  terminal: terminalReducer,
  auth: authReducer,
  forms: formsReducer,
  routing: routerReducer.routerReducer,
  settingsTitle: settingsTitleReducer,
  users: usersReducer,
  temp: tempReducer,
};
