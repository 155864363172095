<div  role="main">
  <!-- Resources -->
  <div >
    <h2>fiche profil</h2>
    <p>Veuillez vérifier vos informations :</p>
    <div *ngFor="let record of dataSource.data ; let i = index">
      {{ i }}
      {{ record.wme_name }}
    </div>
  </div>
  <section class="generic-section">
    <div class="form-container">
      <form class="form" [formGroup]="form" autocomplete="off">
        <div class="form-icon">
          <svg class="form-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <defs>
              <style>
                .cls-1 {
                  fill: none;
                }

                .cls-2 {
                  fill: #fff;
                }
              </style>
            </defs>
            <g id="Calque_2" data-name="Calque 2">
              <g id="Calque_1-2" data-name="Calque 1">
                <path class="cls-1" d="M0,0H24V24H0Z" />
                <path d="M12,2c-12.7.4-13.08,19.17,0,20C24.7,21.58,25.08,2.81,12,2Z" />
                <path class="cls-2"
                  d="M10.87,13.9c-.35,0-.49-.09-.55-.44L10,11.61c-.06-.36.11-.53.46-.57l2.79-.39c.28-.05.37-.16.37-.44a.34.34,0,0,0-.37-.39H9.41c-.35,0-.51-.14-.51-.5V7.62c0-.35.16-.5.51-.5h4.52c1.82,0,2.9.87,2.9,2.46v1.47c0,1.57-.84,2.15-2.17,2.33Zm-.34,3.3c-.37,0-.5-.15-.5-.5V14.86c0-.36.13-.5.5-.5h2.56c.35,0,.51.14.51.5V16.7c0,.35-.16.5-.51.5Z" />
              </g>
            </g>
          </svg>
        </div>
        <div class="form-title">compte</div>
        <div class="form-content-column">
          <div class="form-content-row" fxLayoutGap="15px">
            <div class="form-fieldset w50">
              <label class="label-pre">field01</label>
              <input id="focus-00" formControlName="field01" matInput type="text" placeholder="initials" />
              <div class="field-status-error"></div>
            </div>
            <div class="form-fieldset">
              <label class="label-pre">field02</label>
              <input formControlName="field02" matInput type="text" placeholder="nickname" />
              <div class="field-status-error"></div>
            </div>
          </div>
          <div class="form-content-row" fxLayoutGap="15px">
            <div class="form-fieldset w50">
              <label class="label-pre">field03</label>
              <input formControlName="field03" matInput type="text" placeholder="firstname" />
              <div class="field-status-error"></div>
            </div>
            <div class="form-fieldset">
              <label class="label-pre">field04</label>
              <input formControlName="field04" matInput type="text" placeholder="name" />
              <div class="field-status-error"></div>
            </div>
          </div>
          <div class="form-content-row" fxLayoutGap="15px">
            <div class="form-fieldset">
              <label class="label-pre">field05</label>
              <input formControlName="field05" matInput type="text" placeholder="password" />
              <div class="field-status-error"></div>
            </div>
            <div class="form-fieldset">
              <label class="label-pre">field06</label>
              <input formControlName="field06" matInput type="password" placeholder="password" />
              <div class="field-status-error"></div>
            </div>
          </div>
          <div class="form-content-row" fxLayoutGap="15px">
            <div class="form-fieldset">
              <label class="label-pre">field07</label>
              <input formControlName="field07" matInput type="text" placeholder="email" />
              <div class="field-status-error"></div>
            </div>
            <div class="form-fieldset w25">
              <label class="label-pre">field08</label>
              <input formControlName="field08" matInput type="text" placeholder="role" />
              <div class="field-status-error"></div>
            </div>
          </div>
        </div>
        <div class="form-footer" fxLayoutGap="15px">
          <button class="button" mat-raised-button (click)="close()">fermer</button>
          <button class="button" mat-raised-button (click)="close()">mettre à jour</button>
        </div>
      </form>
    </div>
  </section>
  <section class="generic-section">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortDatas($event)" class="dataSource-table">
      <ng-container *ngFor="let col of displayedColumns" matColumnDef="{{col}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="{{col}}" class="dataSource-header-celldef"> {{col}}
          <!-- <button mat-icon-button (click)="$event.stopPropagation(); openFilter(col)">
          <img src="assets/icons/search-24px.svg">
        </button>
        <input type="text" hidden id="{{col}}-filter" (blur)="filderData(col, $event.target.value); closeFilter(col)" /> -->
        </th>
        <td mat-cell *matCellDef="let record" class="dataSource-header-celldef"> {{record[col]}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25,50,100]" showFirstLastButtons> </mat-paginator>
    <span class="table-search">
      <div style="color: var(--appcolor-white);">Recherche : </div>
      <input type="text" hidden id="filter" (blur)="filderData($event, $event.target.value); closeFilter($event)" />
      <button mat-icon-button (click)="$event.stopPropagation(); openFilter($event)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="var(appicon-fill-color)" width="18px" height="18px">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M15.5 14h-.79l-.28-.27c1.2-1.4 1.82-3.31 1.48-5.34-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.79 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59 2.03.34 3.94-.28 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        </svg>
      </button>
    </span>
  </section>
</div>
