import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { TerminalService } from 'src/app/shared/services/terminal.service';
import { State } from 'src/app/shared/store';
import { SetTerminalStateMessage, UpdateTerminalStateMessage } from 'src/app/shared/store/terminal-store/terminal.actions';
import { terminalConsoleSelector, terminalMessageSelector } from 'src/app/shared/store/terminal-store/terminal.selectors';

@Component({
  selector: 'app-terminaldash',
  templateUrl: './terminaldash.component.html',
  styleUrls: ['./terminaldash.component.css']
})
export class TerminaldashComponent implements OnInit, AfterViewInit, OnChanges, AfterViewChecked, AfterContentInit {

  public terminalMessage$: Observable<{}>;

  constructor(
    private store: Store<State>,
    private terminalService: TerminalService,
  ) {

  }

  ngOnInit(): void {
    // this.store.dispatch(new Logout());

    this.terminalMessage$ = this.terminalService.getTerminalMessage();
    console.log('=>[ TerminaldashComponent/terminalMessage$ ]', this.terminalMessage$);
    this.store.dispatch(new SetTerminalStateMessage('first message'));
    setTimeout(() => {
      this.store.dispatch(new SetTerminalStateMessage('second message'));
    }, 5000);
  }

  ngAfterViewInit(): void {

  }

  ngAfterViewChecked(): void {
  }

  ngAfterContentInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

  }
}


