<div *ngFor="let alert of alerts" class="{{cssClass(alert)}}" [@flyInOut]="'in'">
  <span [innerHTML]="alert.message"></span>
  <span fxFlex="auto"> </span>
    <svg class="alert-icon" data-name="icon-rounded-01" (click)="removeAlert(alert)" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24">
      <path d="M0,0H24V24H0Z" style="fill:none" />
      <path d="M12,2c-12.7.4-13.08,19.17,0,20C24.7,21.58,25.08,2.81,12,2Z"
        style="fill:#fff;stroke:#1d1d1b;stroke-miterlimit:10" />
      <path
        d="M9.67,15.63c-.3,0-.43-.13-.43-.43V13.75c0-.3.13-.43.43-.43H11v-4H9.91c-.3,0-.43-.13-.43-.43V7.43c0-.3.13-.43.43-.43h3.48c.3,0,.43.13.43.43v5.89H15c.3,0,.43.13.43.43V15.2c0,.3-.13.43-.43.43Z" />
    </svg>
</div>
