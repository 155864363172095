<!-- app-root -->
<div>

  <!-- <app-header></app-header> -->
  <!-- <app-topbar></app-topbar> -->
  <!-- <app-toolbar></app-toolbar> -->
  <app-alert></app-alert>
  <router-outlet></router-outlet>
  <!-- <app-footer></app-footer> -->

</div>
