import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { State } from '..';
import { Store, select } from '@ngrx/store';
import { map, switchMap, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import {
  TRY_SIGNUP, TrySignup, SignupError,
  TRY_SIGNIN, TrySignin, SigninError,
  SIGNIN_SUCCESS, SigninSuccess, SIGNIN_ERROR, TRY_REFRESH_TOKEN,
  LOGOUT, Logout, TRY_FETCH_CURRENT_USER,
  TryFetchCurrentUser, SetCurrentUser, UPDATE_CURRENT_USER
} from './auth.actions';

import { HttpResponse } from '../../models/httpResponse.model';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { tokenSelector } from './auth.selectors';
import { SnackBarComponent } from '../../components/snack-bar/snackbar.component';

@Injectable()
export class AuthEffects {
  private sub: Subscription;

  trySignUp$ = createEffect(() => this.actions$.pipe(
    ofType(TRY_SIGNUP),
    map((action: TrySignup) => action.payload),
    switchMap((user: User) => {
      return this.authService.signup(user).pipe(
        switchMap(() => {
          this.router.navigate(['/home/signin']);
          return EMPTY;
        }),
        catchError((err: any) => {
          return of(new SignupError(err));
        })
      );
    }),
  ));

  trySignIn$ = createEffect(() => this.actions$.pipe(
    ofType(TRY_SIGNIN),
    map((action: TrySignin) => action.payload),
    switchMap((credentials: { email: string, password: string }) => {
      return this.authService.signin(credentials).pipe(
        map((resp: HttpResponse) => {
          console.log('[ trySignIn/ ]: ', resp, '/', ((new Date().toUTCString())));
          if (resp.datas) {
            // localStorage.setItem('token', resp.datas);
            return new SigninSuccess(resp.datas);
          } else {
            this.snackBar.openSnackBarInfos(resp.message, 'fermer');
            return new SigninError(resp.message);
          }
        })
      );
    }),
  ));

  signinSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SIGNIN_SUCCESS),
      tap(() => {
        if (!this.sub) {
          // this.sub = this.authService.initRefreshToken().subscribe();
        }
        this.router.navigate(['/home']);
        this.store.dispatch(new TryFetchCurrentUser());
      })
    ), { dispatch: false }
  );

  tryFetchCurrentUser$ = createEffect(() => this.actions$.pipe(
    ofType(TRY_FETCH_CURRENT_USER),
    switchMap(() => {
      return this.userService.getCurrentUser().pipe(
        map((resp: HttpResponse) => {
          if (resp.datas) {
            // console.log('->[ tryFetchCurrentUser/ ]: ', resp.datas);
            return new SetCurrentUser(resp.datas);
          } else {
            return new Logout();
          }
        }),
        catchError((err: any) => {
          return EMPTY;
        })
      );
    }),
  ));

  UpdateCurrentUser$ = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_CURRENT_USER),
    // map((action: UpdateUser) => action.payload),
    switchMap((user: User) => {
      return this.userService.updateCurrentUser(user).pipe(
        switchMap(() => {
          console.log('->[ ConsoleLogEncours/test ]: ', user);
          this.router.navigate(['/signin']);
          return EMPTY;
        }),
        // catchError((err: any) => {
        //   return of(new UpdateCurrentUserError(err));
        // })
      );
    }),
  ), { dispatch: false }
  );

  tryRefreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(TRY_REFRESH_TOKEN),
    withLatestFrom(this.store.pipe(select(tokenSelector))),
    switchMap(([action, token]) => {
      if (token) {
        return this.authService.refreshToken().pipe(
          map((newToken: string) => {
            localStorage.setItem('token', newToken);
            return new SigninSuccess(newToken);
          }),
          catchError((err: any) => {
            // console.log('->[ tryRefreshToken$/error ]: ', err);
            localStorage.removeItem('token');
            if (this.sub) {
              this.sub.unsubscribe();
            }
            return EMPTY;
          })
        );
      } else {
        return EMPTY;
      }
    })
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(LOGOUT),
    tap(() => {
      if (this.sub) {
        this.sub.unsubscribe();
      }
      // localStorage.removeItem('token');
      this.router.navigate(['/']);
    })
  ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private store: Store<State>,
    private router: Router,
    private snackBar: SnackBarComponent
  ) { }



}
