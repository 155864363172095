<mat-toolbar class="topbar" fxLayoutAlign="start center">
  <span class="topbar-logo">
    <img src="../../../assets/img/topbar/topbar-logo.svg" alt="iasct logo topbar" />
  </span>
  <span class="topbar-logo-text">
    <img src="../../../assets/img/topbar/topbar-logo-text.svg" alt="iasct logo text topbar" />
  </span>
  <div fxFlex="auto"> </div>
  <!-- <div class="topbar-center">
    <p class="topbar-center-text">maîtrisez votre identité visuelle et votre présence numérique</p>
  </div> -->

  <div fxFlex="auto"> </div>
  <!-- left menu -->
  <div class="topbar-menu" fxLayout="row" fxLayoutAlign="end center">
    <!-- router enabled -->
    <div *ngIf="(isrouterEnabled$ | async)" fxLayout="row" fxLayoutGap="5px">
      <svg class="icons link text-red" data-name="icon-profile" routerLink="/profile" matTooltip="sauvegarder"
        matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          d="M17.59 3.59c-.38-.38-.89-.59-1.42-.59H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7.83c0-.53-.21-1.04-.59-1.41l-2.82-2.83zM12 19c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm1-10H7c-1.1 0-2-.9-2-2s.9-2 2-2h6c1.1 0 2 .9 2 2s-.9 2-2 2z" />
      </svg>
    </div>
    <div *ngIf="(isLoggedin$ | async) && !(isrouterEnabled$ | async)" fxLayout="row" fxLayoutGap="5px">
      <svg class="icons link" data-name="icon-profile" routerLink="/profile" matTooltip="profile"
        matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm1 14H8c-.55 0-1-.45-1-1s.45-1 1-1h5c.55 0 1 .45 1 1s-.45 1-1 1zm3-4H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z" />
      </svg>
      <svg class="icons link" data-name="icon-logout" (click)="logout()" matTooltip="logg out"
        matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.4 22.4">
        <path d="M10.9,19.9c-4.7-0.1-8.6-4.3-8.5-9c0.1-2.1,0.9-4.2,2.4-5.7C5,5,5.4,4.8,5.7,4.8c0.3,0,0.7,0.1,0.9,0.4
                c0.5,0.5,0.5,1.3,0,1.8C5.6,8.1,5,9.6,5,11.2c0,1.6,0.6,3.2,1.8,4.3c1.2,1.2,2.7,1.8,4.3,1.8c1.7,0,3.2-0.7,4.4-1.9
                c1.2-1.2,1.8-2.8,1.8-4.4c0-1.5-0.6-2.9-1.6-4c-0.5-0.5-0.5-1.3,0-1.8c0.2-0.2,0.6-0.4,0.9-0.4c0.4,0,0.7,0.2,1,0.4
                c1.5,1.6,2.4,3.7,2.4,5.9c0,2.3-0.9,4.5-2.6,6.2c-1.7,1.7-3.9,2.6-6.2,2.6L10.9,19.9z M11.2,12.5c-0.7,0-1.3-0.6-1.3-1.3V3.7
                c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v7.5C12.5,11.9,11.9,12.5,11.2,12.5z" />
        <path d="M11.2,2.8c0.5,0,0.9,0.4,0.9,0.9v7.5c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9V3.7C10.2,3.2,10.7,2.8,11.2,2.8
                 M16.6,5.2c0.2,0,0.5,0.1,0.7,0.3c1.4,1.5,2.3,3.5,2.3,5.7c0,4.6-3.8,8.4-8.4,8.4c-0.1,0-0.2,0-0.3,0c-4.5-0.1-8.2-4.1-8.1-8.6
                c0.1-2.1,0.9-4,2.3-5.5c0.2-0.2,0.4-0.3,0.7-0.3c0.2,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,0.9,0,1.3c-1.1,1.2-1.7,2.7-1.7,4.4
                c0,3.6,2.9,6.5,6.5,6.5c0,0,0.1,0,0.1,0c3.6,0,6.5-3.1,6.4-6.7c0-1.6-0.7-3.1-1.7-4.3c-0.3-0.4-0.4-0.9,0-1.3
                C16.2,5.3,16.4,5.2,16.6,5.2 M11.2,2c-0.9,0-1.7,0.8-1.7,1.7v7.5c0,0.9,0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7V3.7
                C12.9,2.8,12.1,2,11.2,2L11.2,2z M16.6,4.4c-0.5,0-0.9,0.2-1.2,0.5c-0.6,0.6-0.7,1.7,0,2.3c1,1,1.5,2.4,1.5,3.8
                c0,1.5-0.5,3-1.6,4.1c-1.1,1.1-2.5,1.8-4,1.8l-0.1,0c-3.2,0-5.8-2.6-5.8-5.8C5.4,9.7,6,8.3,7,7.3c0.6-0.7,0.6-1.7,0-2.3
                C6.6,4.6,6.2,4.4,5.7,4.4C5.3,4.4,4.8,4.6,4.5,5C3,6.6,2.1,8.7,2,10.9c-0.1,4.9,3.9,9.2,8.8,9.4c0.1,0,0.2,0,0.3,0
                c5,0,9.1-4.1,9.1-9.1c0-2.3-0.9-4.5-2.5-6.2C17.5,4.6,17.1,4.4,16.6,4.4L16.6,4.4z" />
      </svg>
    </div>
    <div *ngIf="!(isLoggedin$ | async)" fxLayout="row" fxLayoutAlign="center end">
      <img class="icons link" src="../../../../assets/img/svg/_icons/signin.svg" alt="" data-name="icon-signin"
        routerLink="/signin" matTooltip="logg in" matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500">
      <img class="icons link" src="../../../../assets/img/svg/_icons/signup.svg" alt="" data-name="icon-signup"
        routerLink="/signup" matTooltip="sign up" matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    </div>
    <!-- <img class="icons link" src="../../../../assets/img/svg/_icons/menu.svg" alt="" data-name="icon-menu"
      [matMenuTriggerFor]="appMenu" matTooltip="menu" matTooltipClass="tooltip-button-small" [matTooltipShowDelay]="500"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> -->
  </div>
  <mat-menu #appMenu="matMenu">
    <button mat-menu-item>Notre équipe</button>
    <button mat-menu-item>Nos compétences</button>
    <button mat-menu-item>Contacts</button>
    <button mat-menu-item>Espace clients</button>
  </mat-menu>
</mat-toolbar>
