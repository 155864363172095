<!-- Video Component -->
<div  role="main" (window:resize)="setVideoWrapper()">

  <!-- <video class="videoPlayer" controls width={{wideoWidth}} autoplay loop preload="auto">
    <source src="../../../assets/medias/showreeltb02.mp4" type="video/mp4">
    This browser does not support the HTML5 video element.
  </video> -->
  <div #carousel class="videoCard-carousel">
    <!-- *ngIf="(sectionIndex !== 1)"  -->
    <a class="carousel-icon-left" (click)="slideSections(0)" (swipeleft)="slideSections(0)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M2.93,17.07a10,10,0,1,0,0-14.14A10,10,0,0,0,2.93,17.07ZM8.34,9h6a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1h-6v1.79a.5.5,0,0,1-.85.37L4.7,10.36a.5.5,0,0,1,0-.71L7.49,6.87a.49.49,0,0,1,.85.35Z" />
      </svg>
    </a>
    <div #videoCardContainer class="videoCard-container">
      <!-- videoCard 01 préproduction-->
      <section *ngFor="let s of sectionsCount, index as i" #section id="section{{s}}" class="videoCard-section">
        <div *ngFor="let vc of videosCount[s], index as ii" #videoCard id="videoCard{{s * 10 + vc}}" class="videoCard"
          (click)="formatFileList()">
          <!-- <div class="videoCard-icon">
            <svg class="videoCard-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M16 3.33c2.58 0 4.67 2.09 4.67 4.67H22c0-3.31-2.69-6-6-6v1.33M16 6c1.11 0 2 .89 2 2h1.33c0-1.84-1.49-3.33-3.33-3.33V6" />
              <path d="M24 0H0v24h24V0z" fill="none" />
              <path
                d="M17 9c0-1.11-.89-2-2-2V4H9L7.17 6H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9h-5zm-5 10c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
            </svg>
          </div> -->
          <!-- <div class="videoCard-title">{{filesList[vc]}} -->
            <!-- <div class="videoCard-title">  -->
            <!-- <div class="videoCard-line"></div> -->
          <!-- </div> -->
          <div class="videoCard-content">
            <video class="videoPlayer" width={{videoWidth}} loop preload="auto">
              <source src="{{mediasPath}}/{{filesList[vc]}}" type="video/mp4">
              This browser does not support the HTML5 video element.
            </video>
            <!-- <img class="videoCardimage" src="../../../../assets/img/little-big-cam-captation.png"
              alt="Grapefruit slice atop a pile of other slices"> -->
          </div>
          <!-- <div class="videoCard-footer" fxLayoutGap="15px">
          </div> -->
        </div>
      </section>
    </div>
    <div class="carousel-icon-right" (click)="slideSections(1)" (swiperight)="slideSections(0)">
      <!-- *ngIf="(sectionIndex !== 4)" -->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M17.07,2.93a10,10,0,1,0,0,14.14A10,10,0,0,0,17.07,2.93ZM11.65,11h-6a1,1,0,0,1-1-1h0a1,1,0,0,1,1-1h6V7.21a.51.51,0,0,1,.86-.36l2.78,2.79a.48.48,0,0,1,0,.7l-2.78,2.79a.5.5,0,0,1-.85-.35Z" />
      </svg>
    </div>
  </div>

</div>
