import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-idlogo',
  templateUrl: './idlogo.component.html',
  styleUrls: ['./idlogo.component.css']
})
export class IdLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
