// Modules natifs
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBarModule, MatSnackBarRef, MAT_SNACK_BAR_DATA, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import 'hammerjs';

// Modules
import { CoreModule } from './shared/modules/core.module';
import { LayoutModule } from './shared/modules/layout.module';
import { RoutingModule } from './shared/modules/routing.module';

// Components
import { AppComponent } from './app.component';
import { p404Component } from './front/p404/p404.component';
import { TopbarComponent } from './shared/components/topbar/topbar.component';
import { FrontComponent } from './front/front.component';
import { OverviewComponent } from './front/overview/overview.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ToolbarComponent } from './shared/components/toolbar/toolbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AlertComponent } from './shared/components/alert/alert.component';
import { SnackBarComponent } from './shared/components/snack-bar/snackbar.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { SigninComponent } from './front/auth/signin/signin.component';
import { SignupComponent } from './front/auth/signup/signup.component';
import { ProfileComponent } from './front/users/profile/profile.component';
import { UsersComponent } from './front/users/users.component';
import { MediaComponent } from './front/medias/media.component';
import { ExplorerComponent } from './front/explorer/explorer.component';
import { GenericComponent } from './back/generic/generic.component';
import { IdLogoComponent } from './shared/components/idlogo/idlogo.component';
import { IconMenuComponent } from './shared/components/iconmenu/iconmenu.component';
import { RadarComponent } from './shared/components/radar/radar.component';
import { SvgSlideComponent } from './shared/components/svg-slide/svg-slide.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { StartComponent } from './shared/components/start/start.component';
import { TerminalComponent } from './front/terminal/terminal.component';
import { CryptoComponent } from './front/terminal/crypto/crypto.component';

// Store
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { environment } from 'src/environments/environment';

// Reducers & Effects & RouterStateSerializer
import { reducersMap } from './shared/store';
import { MyRouterStateSerializer } from './shared/store/router-store/router.serializer';
import { RouterEffects } from './shared/store/router-store/router.effects';
import { AuthEffects } from './shared/store/auth-store/auth.effects';
import { TempEffects } from './shared/store/temp-store/temp.effects';
import { TerminaldashComponent } from './front/terminal/terminaldash/terminaldash.component';

@NgModule({
  declarations: [
    AppComponent,
    FrontComponent,
    TopbarComponent,
    SigninComponent,
    SignupComponent,
    ProfileComponent,
    UsersComponent,
    DialogComponent,
    SnackBarComponent,
    AlertComponent,
    ToolbarComponent,
    FooterComponent,
    MediaComponent,
    OverviewComponent,
    HeaderComponent,
    ExplorerComponent,
    GenericComponent,
    p404Component,
    IdLogoComponent,
    IconMenuComponent,
    ProgressBarComponent,
    RadarComponent,
    SvgSlideComponent,
    StartComponent,
    TerminalComponent,
    CryptoComponent,
    TerminaldashComponent,
  ],
  entryComponents: [
    DialogComponent,
    SnackBarComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    LayoutModule,
    MatDialogModule,
    MatSnackBarModule,
    RoutingModule,
    StoreModule.forRoot(
      reducersMap, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      },
    }),
    StoreDevtoolsModule.instrument({
      name: 'IASCT Editions',
      logOnly: environment.production
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'routing'
    }),
    EffectsModule.forRoot([
      RouterEffects,
      AuthEffects,
      // UsersEffects
      TempEffects
    ]),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: RouterStateSerializer, useClass: MyRouterStateSerializer
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MatSnackBarRef,
      useValue: {}
    },
    {
      provide: MAT_SNACK_BAR_DATA,
      useValue: {}
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2000, verticalPosition: 'top', horizontalPosition: 'right' }
    },
    DialogComponent,
    SnackBarComponent

  ],
  bootstrap: [
    AppComponent
    // SnackBarComponent
  ]
})
export class AppModule { }

