import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TerminalState } from '../../models/stateIndex.model';

export const terminalSelector = createFeatureSelector<TerminalState>('terminal');
export const terminalStateRoot = createSelector(terminalSelector,
  (tempState: TerminalState) => {
    if (tempState) {
      return tempState;
    } else {
      return null;
    }
  }
);
export const terminalMessageSelector = createSelector(terminalSelector,
  (terminalState: TerminalState) => {
    if (terminalState) {
      return terminalState.message;
    } else {
      return null;
    }
  }
);
export const terminalConsoleSelector = createSelector(terminalSelector,
  (terminalState: TerminalState) => {
    if (terminalState) {
      return terminalState.console;
    } else {
      return null;
    }
  }
);
export const terminalErrorSelector = createSelector(terminalSelector,
  (terminalState: TerminalState) => {
    if (terminalState) {
      return terminalState.error;
    } else {
      return null;
    }
  }
);
