<div class="signin-wrap">
  <div class="form-wrap">
    <div class="form-header">
      <h1>inscription</h1>
      <p>Veuillez enregistrer vos identifiants :</p>
    </div>
    <div class="form-container">
      <form class="form-content" [formGroup]="form">
        <div class="form-title">création de compte</div>
        <div class="form-content-column">
          <div class="form-content-row" fxLayoutGap="15px">
            <div class="form-fieldset">
              <label class="label-pre">email :</label>
              <input formControlName="email" matInput type="text" placeholder="email" />
              <div class="field-status-error"></div>
            </div>
            <div class="form-fieldset">
              <label class="label-pre">password :</label>
              <input formControlName="password" matInput type="password" placeholder="password" />
              <div class="field-status-error"></div>
            </div>
          </div>
          <div class="form-fieldset">
            <label class="label-pre">nom</label>
            <input class="card-field" formControlName="name" matInput type="text" placeholder="nom" />
            <div class="field-status-error"></div>
          </div>
        </div>
        <div *ngIf="error$ | async; let error">
          <div class="form-line"></div>
          <div class="form-footer-message">{{ error }}</div>
        </div>
        <div class="form-footer" fxLayoutGap="35px">
          <button class="button" mat-raised-button (click)="submit()" color="primary">Connexion</button>
        </div>
      </form>
    </div>
  </div>
</div>
