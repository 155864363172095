<div class="terminal-wrap">
  <div class="terminal-cell-A01">
    <div class="terminal-cell-label">TA01</div>
    <div class="terminal-sidebar">
      <div class="terminal-sidebar-main link">
        <span>{{menuTitle}}</span>
      </div>
      <div *ngFor="let menu of menuSubMenu; let i=index" #menus class="terminal-sidebar-item link" attr.data-text="{{menuSubMenu[i].title}}"
        attr.id="{{menuSubMenu[i].path}}" attr.data-index={{i}} routerLink="{{menuSubMenu[i].path}}">
        <span>
          {{menuSubMenu[i].title}}
        </span>
      </div>
    </div>
  </div>

  <div class="terminal-cell-A02">
    <div class="terminal-cell-label">TA02</div>
    <div class="terminal-cell-main">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
