import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Generic } from '../../shared/models/generic';
import { HttpResponse } from '../../shared/models/httpResponse.model';
import { environment } from '../../../environments/environment';
import { ExplorerService } from '../../shared/services/explorer.service';
import { GenericService } from './generic.service';

@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.css',
    '../../shared/css/app.component.css'
  ]
})
export class GenericComponent implements OnInit {
  public form: FormGroup;
  public records: any[];
  public dataSource = new MatTableDataSource<any>();
  public sortedData: any[];
  public displayedColumns: string[] = ['wme_id', 'wme_name', 'wme_description', 'wme_path', 'wme_size', 'wme_lastaccessed', 'wme_lastmodified', 'wme_type'];
  private currentRecord: Generic;
  private sub: Subscription;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private explorerService: ExplorerService,
    private genericService: GenericService,

  ) { }

  ngOnInit(): void {
    this.currentRecord = {
      gen_id: 1,
      gen_field01: 'gen_field01',
      gen_field02: 'gen_field02',
    };

    // get the records using MatTableDataSource
    this.sub = this.explorerService.getFileList(environment.appMediasPath).subscribe((resp: HttpResponse) => {
      this.dataSource.data = resp.datas;
      this.sortedData = this.dataSource.data.slice();
      console.log('->[ GenericComponent/dataSource.data ]: ', this.dataSource.data);
      this.dataSource.paginator = this.paginator;
    });
  }

  // another way to get datas
  private getHTTPresponse(): any {
    this.sub = this.genericService.getTableFields().subscribe((resp: HttpResponse) => {
      console.log('->[ GenericComponent/explorerService.getFileList ]: ', resp);
      let i = 0;
      for (const tablerow of resp.datas) {
        const test = tablerow;
        this.dataSource.data.push(tablerow);
        i++;
      }
      console.log('->[ GenericComponent/table ]: ', this.dataSource.data);
    });
    return this.dataSource.data;
  }

  private createGenericForm(): void {
    this.form = this.fb.group({
      id: new FormControl(this.currentRecord.gen_id),
      field01: new FormControl(this.currentRecord.gen_field01),
      field02: new FormControl(this.currentRecord.gen_field02),
      field03: new FormControl(this.currentRecord.gen_field03),
      field04: new FormControl(this.currentRecord.gen_field04),
      field05: new FormControl(this.currentRecord.gen_field05),
      field06: new FormControl(this.currentRecord.gen_field06),
      field07: new FormControl(this.currentRecord.gen_field07),
      field08: new FormControl(this.currentRecord.gen_field08),
    });
  }

  public close(): void {
    this.router.navigate(['/']);
  }

  public closeFilter(e) {
  }

  public filderData(col, data) {

  }

  public openFilter(e) {

  }

  public sortDatas(sort: Sort): void {
    this.records = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.sortedData = this.records;
      return;
    }
    if ('asc' === sort.direction) {
      this.dataSource.data = this.records.slice().sort(
        (a, b) => a[sort.active] > b[sort.active] ? -1 : a[sort.active] < b[sort.active] ? 1 : 0);
    } else {
      this.dataSource.data = this.records.slice().sort(
        (a, b) => a[sort.active] < b[sort.active] ? -1 : a[sort.active] > b[sort.active] ? 1 : 0);
    }
  }

}
