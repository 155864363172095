import {
  TerminalActions, SET_TERMINAL_STATE_MESSAGE, UPDATE_TERMINAL_STATE_MESSAGE, SET_TERMINAL_STATE_TIME, SET_TERMINAL_STATE_CONSOLE, SET_TERMINAL_STATE_ERROR
} from './terminal.actions';
import { TerminalState } from '../../models/stateIndex.model';

const initialState = {
  path: null,
  message: null,
  time: null,
  console: null,
  error: null
};

export function terminalReducer(state: TerminalState = initialState, action: TerminalActions): TerminalState {
  switch (action.type) {
    case SET_TERMINAL_STATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
        error: null
      };
    case UPDATE_TERMINAL_STATE_MESSAGE: {
      return {
        ...state,
        error: null
      };
    }
    case SET_TERMINAL_STATE_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
  }
  // console.log('=>[ terminalReducer/state ]', state);
  return state;
}
