import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {
  // @HostListener('window:scroll', ['$event']) scroll: any;


  @HostListener('window:wheel', ['$event.deltaY']) onScroll(e) {
    console.log('=>[ OverviewComponent/scrollCatch ]', e);

  }
}
